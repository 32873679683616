import React, { PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { UrlObject } from "node:url";
import { QueryStringParams } from "@ugitgud/legos/util/queryString";
import { useHistory, useLocation, useParams } from "react-router";

type Url = UrlObject | string;

export type PushRoute = (
  route: string | Url,
  as?: Url | undefined,
  options?: Record<string, Record<string, string | boolean> | string | boolean>,
) => void;

type RoutingProps = {
  push: PushRoute;
  path: string;
  query: QueryStringParams;
  basePath?: string;
  back?: () => void;
  forward?: () => void;
  canGoBack?: boolean;
  canGoForward?: boolean;
  search: QueryStringParams;
};

const RoutingContext = React.createContext<RoutingProps>({
  push: (route) => {
    throw "UGG Routing Context not found! Did you forget to wrap your application in a `<UggRoutingProvider>`?";
  },
  canGoBack: false,
  canGoForward: false,
  back: () => {},
  forward: () => {},
  path: "/",
  query: {},
  search: {},
});

export const UGGRoutingProvider: React.FC<RoutingProps & { children: React.ReactNode }> = ({ children, ...rest }) => {
  return <RoutingContext.Provider value={rest}>{children}</RoutingContext.Provider>;
};

export const UGGLegacyRouteAdapter: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useHistory();
  const path = useLocation();
  const fullUrl = `${path.pathname}${path.search}${path.hash}`;
  const query = useMemo(() => Object.fromEntries(new URLSearchParams(path.search)), [path.search]);
  const push: PushRoute = useCallback(
    (route, as, options) => {
      const shouldReplace =
        typeof route === "string"
          ? route === fullUrl
          : route.pathname === path.pathname && route.query === query.toString() && route.hash === path.hash;
      if (typeof route === "object") {
        if (route.query) {
          navigate.replace(route.query as { [k: string]: string }, {
            state: { prev: path },
            replace: !!options?.["replace"] || shouldReplace,
          });
          return;
        }

        navigate.push(
          { pathname: route?.pathname!, search: route?.search! },
          { ...options, replace: !!options?.["replace"] || shouldReplace },
        );
        return;
      }
      navigate.push(route.toString(), { ...options, replace: !!options?.["replace"] || shouldReplace });
    },
    [navigate],
  );
  return (
    <UGGRoutingProvider search={query} push={push} query={query} path={path.pathname}>
      {children}
    </UGGRoutingProvider>
  );
};

/**
 * Valorant route provider, keeps components routing framework agnostic.
 *
 * @returns A function that performs page routing
 */
export const useUggRouting = () => {
  return useContext(RoutingContext);
};
