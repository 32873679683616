import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { getApiRoot } from "@ugg/shared/api/api-helpers";
import { useUGGApiVersions } from "@ugg/shared/api/requests/ugg-api-versions";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RankS } from "@ugg/shared/utils/rank-helpers";

// Data Parsers
import { cleanTierList } from "@ugg/shared/api/data-parser/tier-lists/tier-list";

// Interfaces
import { TierList } from "@ugg/shared/interfaces/tier-lists/tier-list.interface";

// ------------------------------------------------------------------------------------------------------------

interface TierListParams {
  region: string;
  patch: string;
  queueType: string;
  rank: string;
}

// ------------------------------------------------------------------------------------------------------------
// TIER LIST
// ------------------------------------------------------------------------------------------------------------

function useTierListURL(params: TierListParams) {
  const { data: version } = useUGGApiVersions({
    apiKey: "champion_ranking",
    patch: params.patch,
  });

  if (
    params.queueType === QueueTypeS.ARAM ||
    params.queueType === QueueTypeS.URF ||
    params.queueType === QueueTypeS.ARURF ||
    params.queueType === QueueTypeS.PICK_URF ||
    params.queueType === QueueTypeS.NEXUS_BLITZ ||
    params.queueType === QueueTypeS.NORMAL_BLIND ||
    params.queueType === QueueTypeS.QUICKPLAY ||
    params.queueType === QueueTypeS.SWIFTPLAY ||
    params.queueType === QueueTypeS.NORMAL_DRAFT ||
    params.queueType === QueueTypeS.ONE_FOR_ALL ||
    params.queueType === QueueTypeS.ARENA
  ) {
    params.rank = RankS.OVERALL;
  }

  if (params.queueType === QueueTypeS.ARURF) {
    params.queueType = QueueTypeS.URF;
  }

  const root = getApiRoot(params.patch);
  return [`${root}/champion_ranking`, params.region, params.patch, params.queueType, params.rank, `${version}.json`].join("/");
}

export function useTierList<K = ReturnType<typeof cleanTierList>>(
  page: keyof typeof validParams,
  options?: { params?: Record<string, any> } & JSONFetcherOptions<TierList, K>,
) {
  const { params = {}, ssr = false, skip = false, onCompleted, customCacheKey } = options || {};
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(page, params, true);
  const url = useTierListURL(validatedQueryParams);
  if (onCompleted) {
    return useJSONFetcher<TierList, K>(url, {
      skip,
      ssr,
      customCacheKey,
      onCompleted,
    });
  }

  return useJSONFetcher<TierList, ReturnType<typeof cleanTierList>>(url, {
    skip,
    ssr,
    onCompleted: (url, json) => cleanTierList(json),
  });
}
