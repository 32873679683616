import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Tag from "./Tag";
import { ReactComponent as ChevronUp } from "../assets/chevron-up.svg";

import type { SideNavLinksContainerProps, SideNavLinkProps, SideNavDropdownLinkProps, isActive } from "@typings";
import { useSideNavContext } from "./SideNavContext";

export function SideNavLinksContainer(props: SideNavLinksContainerProps) {
  const { className } = props;
  return <div className={classnames("side-nav_links", className)}>{props.children}</div>;
}

const getActiveState = (props: { path: string; active?: boolean | ((path: string) => boolean) }) => {
  if (typeof props?.active === "function") {
    return props?.active(props.path);
  }

  return props.active;
};

export function SideNavLink(props: SideNavLinkProps) {
  const { isOpen } = useSideNavContext();
  const { label, path, iconSvg, iconSrc, iconSrcActive, active, tag, children } = props;
  const hasDropdown = Array.isArray(children) ? children?.length > 0 : children;
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setOpenDropdown(false);
    }
  }, [isOpen]);

  const toggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDropdown(!openDropdown);
  };

  const currentIsActive = getActiveState(props);

  return (
    <div>
      <LinkComponent {...props}>
        {tag && <Tag tag={tag} mini />}
        <div className="side-nav_link__icon">
          {iconSvg || <img src={iconSrcActive && currentIsActive ? iconSrcActive : iconSrc} />}
        </div>
        <div className="side-nav_link__label">
          {label}
          {tag && <Tag tag={tag} />}
        </div>
        {hasDropdown && (
          <div className="side-nav_link__dropdown-button" onClick={toggleDropdown}>
            <ChevronUp style={{ rotate: !openDropdown ? "180deg" : "" }} />
          </div>
        )}
      </LinkComponent>
      {hasDropdown && openDropdown && <div className="side-nav_dropdown-menu">{children}</div>}
    </div>
  );
}

/************************************************************************************************************************/

type LinkComponentProps = (SideNavLinkProps | SideNavDropdownLinkProps) & {
  children?: React.ReactNode | React.ReactNode[];
};

function LinkComponent(props: LinkComponentProps) {
  const { className, children, WrapperLink, onClick } = props;

  const sideNavLinkClassNames = classnames(
    "side-nav_link",
    {
      "side-nav_link__active": getActiveState(props),
    },
    className,
  );

  return (
    <WrapperLink {...props}>
      <div className={sideNavLinkClassNames} onClick={() => onClick && onClick()}>
        {children}
      </div>
    </WrapperLink>
  );
}

/************************************************************************************************************************/

export function SideNavDropdownLink(props: SideNavDropdownLinkProps) {
  return (
    <LinkComponent {...props}>
      <div className="side-nav_link__label">{props.label}</div>
    </LinkComponent>
  );
}
