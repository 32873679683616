import { window } from "global";
import React, { useState, useCallback, useEffect } from "react";
import { ResponsiveContextProvider } from "./ResponsiveContext";

function getWindowBreakpoint(width, breakpoints, defaultBreakpoint) {
  if (!width && defaultBreakpoint) return defaultBreakpoint;

  for (let [breakpoint, breakpointWidth] of Object.entries(breakpoints)) {
    if (width >= breakpointWidth[0] && width <= breakpointWidth[1]) {
      return breakpoint;
    }
  }
  return defaultBreakpoint;
}

export const ResponsiveProvider = (props) => {
  const { breakpoints, defaultBreakpoint } = props;
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getWindowBreakpoint(window?.innerWidth, breakpoints, defaultBreakpoint),
  );

  const resizeHandler = useCallback(() => {
    const breakpoint = getWindowBreakpoint(window?.innerWidth, breakpoints, defaultBreakpoint);
    if (breakpoint !== currentBreakpoint) {
      setCurrentBreakpoint(breakpoint);
    }
  }, [currentBreakpoint, breakpoints, defaultBreakpoint]);

  useEffect(() => {
    window?.addEventListener("resize", resizeHandler);

    return () => window?.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);

  return (
    <ResponsiveContextProvider value={{ currentBreakpoint, breakpoints, defaultBreakpoint }}>
      {props.children}
    </ResponsiveContextProvider>
  );
};
