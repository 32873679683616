import { window } from "global";
import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { useSideNavContext } from "./SideNavContext";
import { ReactComponent as BlueManPlus } from "../assets/blue-man-plus.svg";
import { ReactComponent as ThreeDots } from "../assets/three-dots.svg";

import type { FooterProps, FooterMenuProps } from "@typings";

export function Footer(props: FooterProps) {
  const { children, className } = props;

  return (
    <div className={classnames("side-nav_footer", className)}>
      <div className="overflow-container">{children}</div>
    </div>
  );
}

export function FooterMenu(props: FooterMenuProps) {
  const footerLinksRef = useRef<HTMLDivElement>();
  const [showFooterMenu, setShowFooterMenu] = useState(false);
  const { isOpen } = useSideNavContext();
  const { className } = props;

  useEffect(() => {
    if (!isOpen) {
      setShowFooterMenu(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (!footerLinksRef.current?.contains(e.target as HTMLElement)) {
        setShowFooterMenu(false);
      }
    };
    window && document.addEventListener("click", handleOutsideClick);

    return () => {
      window && document.removeEventListener("click", handleOutsideClick);
    };
  }, [footerLinksRef.current]);

  return (
    <div className={classnames("footer_menu-container", className)} ref={footerLinksRef}>
      {showFooterMenu && (
        <div className="footer_menu">
          {React.Children.map(props.children, (child) =>
            React.cloneElement(child, { className: classnames("footer_link", child.props.className) }),
          )}
        </div>
      )}
      <ThreeDots className="three-dots" onClick={() => setShowFooterMenu(!showFooterMenu)} />
    </div>
  );
}

/************************************************************************************************************************/

interface GuestProps {
  clickable?: boolean;
}

export function Guest(props: GuestProps) {
  const { clickable } = props;

  const userClassNames = classnames("user guest", {
    user_clickable: clickable,
  });

  return (
    <div className={userClassNames}>
      <div className="user_icon">
        <BlueManPlus className="guest-icon" />
      </div>
      <div className="user_name">Guest</div>
      <div className="online-status_label">Logged Out</div>
    </div>
  );
}

/************************************************************************************************************************/

interface MemberProps {
  clickable?: boolean;
  memberIcon?: string;
  memberLabel?: string;
  statusLabel?: string;
  statusColor?: string;
}

export function Member(props: MemberProps) {
  const { clickable, memberIcon, memberLabel, statusLabel, statusColor } = props;

  const userClassNames = classnames("user member", {
    user_clickable: clickable,
  });

  return (
    <div className={userClassNames}>
      <div className="user_icon">
        <img className="member-icon" src={memberIcon} />
        <div className="online-status_dot" style={{ backgroundColor: statusColor }} />
      </div>
      <div className="user_name">{memberLabel}</div>
      <div className="online-status_label" style={{ color: statusColor }}>
        {statusLabel || "Online"}
      </div>
    </div>
  );
}
