import { useSideNavContext } from "./SideNavContext";
import { ReactComponent as UggLogoU } from "../assets/ugg-logos/ugg-u-logo.svg";
import { ReactComponent as UggLogoUDark } from "../assets/ugg-logos/ugg-u-logo-dark.svg";
import { ReactComponent as FullUggLogo } from "../assets/ugg-logos/ugg-logo-blue.svg";
import { ReactComponent as FullUggLogoDark } from "../assets/ugg-logos/ugg-logo-blue-dark.svg";

import type { SideNavHeaderProps } from "@typings";

export function SideNavHeader(props: SideNavHeaderProps) {
  const { theme, isOpen } = useSideNavContext();
  const { LogoWrapper } = props;
  const lightModeOn = theme === "light";

  const uggLogo = isOpen ? <FullUggLogo /> : <UggLogoU />;
  const uggLogoDark = isOpen ? <FullUggLogoDark /> : <UggLogoUDark />;

  return (
    <div className="side-nav_head">
      <div className="side-nav_head__outer">
        <div className="side-nav_head__inner">
          <LogoWrapper>
            <div className="side-nav_ugg-logo">{lightModeOn ? uggLogoDark : uggLogo}</div>
          </LogoWrapper>
        </div>
      </div>
    </div>
  );
}
