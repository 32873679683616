import { renderToString } from "react-dom/server";
import { Helmet } from "react-helmet-async";
import { useUGGPatches } from "@ugg/shared/api/requests/patches";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getItemDescription } from "@ugg/shared/components/Items/ItemsPage/getItem";
import { TIER_LIST_PAGES, SPECIAL_TIER_LIST_PAGES } from "@ugg/shared/pages/tier-list-pages";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";
import { Multibuilds } from "@ugg/shared/interfaces/champions/overview.interface";

const REPLACE_CHAMPION = "{champion}";
const REPLACE_ROLE = "{role}";
const REPLACE_REGION = "{region}";
const REPLACE_LOWER_ID = "LOWER_CHAMPION_ID";

export const useSeoPages = () => {
  const { data: uggPatches } = useUGGPatches();
  const LATEST_PATCH = (uggPatches?.[0] || "").replace("_", ".");
  const SEASON_TEXT = `S${LATEST_PATCH.split(".")[0]}`;
  const DESCRIPTION_KEYWORDS_SUFFIX = `${SEASON_TEXT} Patch ${LATEST_PATCH}`;

  return {
    index: {
      title: `U GG: The Best League of Legends Builds LoL Build Champion Probuilds LoL Runes Tier List Counters Guides`,
      description:
        "Best Builds from the Best Data. Riot-approved U.GG provides the best League of Legends builds, LoL runes, Probuilds, Tier List, Counters, and more.",
      keywords: `League of legends builds, gg, lol build, league builds, lol runes`,
      url: "https://u.gg",
    },
    open_search: {
      title: "U.GG Open Search",
      description:
        `Search U.GG for League of Legends Statistics. Find Meta Champion ` +
        `Tier Lists, Item and Rune combinations, Pro Builds, Champion ` +
        `Guides, and Champion Counters by Role. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/opensearch",
    },
    about: {
      title: "U.GG About",
      description:
        `About U.GG. Learn more about how we provide you with the best ` +
        `source for League of Legends Statistics. Find Meta Champion Tier ` +
        `Lists, Item and Rune combinations, Pro Builds, Champion Guides, ` +
        `and Champion Counters by Role. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/about",
    },
    terms_of_service: {
      title: "U.GG Terms of Service & Cookies Policy",
      description:
        `Terms of Service. U.GG is, at its heart, a platform to help gamers. ` +
        `Although it is certainly possible to use our platform without creating ` +
        `an account, we do believe that any visitor to our site must abide by ` +
        `the following Terms of Service. Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/terms-of-service",
    },
    privacy_policy: {
      title: "Privacy Policy",
      description:
        `Privacy Policy. U.GG is, at its heart, a platform to help gamers. ` +
        `All Users and their data are protected by our Privacy Policy outlined here.`,
      url: "https://u.gg/privacy-policy",
    },
    privacy_shield: {
      title: "Privacy Shield",
      description:
        `U.GG abides by the principles outlined in the EU-U.S. Privacy Shield framework ` +
        `and the Swiss Privacy Shield with regards to data collection and transmission.`,
      url: "https://u.gg/privacy-shield",
    },
    faq: {
      title: "U.GG Frequently Asked Questions",
      description:
        `Frequently Asked Questions. Why U.GG? What is Unique about ` +
        `U.GG? How does U.GG monetize? How do I navigate U.GG? How does ` +
        `the Search Bar work? What is the U.GG Recommended Build? What ` +
        `filtering options does U.GG offer? Why are your tier lists sorted ` +
        `by win rate, despite being called a tier list? Patch ${LATEST_PATCH}.`,
      url: "https://u.gg/faq",
    },
    [TIER_LIST_PAGES.DEFAULT_TIER_LIST]: {
      title: `U.GG LoL Tier List - Patch {patch} Best Champions for All Roles in League of Legends`,
      description: `The only LoL Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions for Patch {patch} on every role.`,
      keywords: "Lol tier list, league of legends tier list",
      url: "https://u.gg/lol/tier-list",
    },
    [TIER_LIST_PAGES.ADC_TIER_LIST]: {
      title: `U.GG ADC Tier List - Patch {patch} Best ADC Champions in League of Legends`,
      description: `The only ADC Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best ADC champions for Patch {patch}.`,
      keywords: "adc tier list, adc lol",
      url: "https://u.gg/lol/adc-tier-list",
    },
    [TIER_LIST_PAGES.SUPPORT_TIER_LIST]: {
      title: `U.GG Support Tier List - Patch {patch} Best Supp Champions in League of Legends`,
      description: `The only Support Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best supp champions for Patch {patch}.`,
      keywords: "support tier list, lol support tier list",
      url: "https://u.gg/lol/support-tier-list",
    },
    [TIER_LIST_PAGES.TOP_TIER_LIST]: {
      title: `U.GG Top Lane Tier List - Patch {patch} Best Top Champions in League of Legends`,
      description: `The only Top Lane Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best top champions for Patch {patch}.`,
      keywords: "top lane tier list, top tier list",
      url: "https://u.gg/lol/top-lane-tier-list",
    },
    [TIER_LIST_PAGES.MID_TIER_LIST]: {
      title: `U.GG Mid Lane Tier List - Patch {patch} Best Mid Champions in League of Legends`,
      description: `The only Mid Lane Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best mid champions for Patch {patch}.`,
      keywords: "mid lane tier list, mid tier list",
      url: "https://u.gg/lol/mid-lane-tier-list",
    },
    [TIER_LIST_PAGES.JUNGLE_TIER_LIST]: {
      title: `U.GG Jungle Tier List - Patch {patch} Best Jungle Champions in League of Legends`,
      description: `The only Jungle Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best jungle champions for Patch {patch}.`,
      keywords: "jungle tier list, Lol jungle tier list",
      url: "https://u.gg/lol/jungle-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST]: {
      title: `ARURF TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL ARURF Tier List with Riot-approved stats of U.GG. Best ARURF champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "ARURF tier list, Lol ARURF tier list",
      url: "https://u.gg/lol/arurf-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST]: {
      title: `URF TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL URF Tier List with Riot-approved stats of U.GG. Best URF champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "URF tier list, Lol URF tier list",
      url: "https://u.gg/lol/urf-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST]: {
      title: `U.GG ARAM Tier List - Patch {patch} Best ARAM Champions in League of Legends`,
      description: `The only ARAM Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best ARAM champions for Patch {patch}.`,
      keywords: "ARAM tier list, Lol ARAM tier list",
      url: "https://u.gg/lol/aram-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST]: {
      title: `ONE FOR ALL TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL One for All Tier List with Riot-approved stats of U.GG. Best One for All champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "One for All tier list, Lol One for All tier list",
      url: "https://u.gg/lol/one-for-all-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST]: {
      title: `NEXUS BLITZ TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `LoL Nexus Blitz Tier List with Riot-approved stats of U.GG. Best Nexus Blitz champions based on millions of League of Legends matches. Sort by win rate, tier, role, rank, and region. Patch {patch}`,
      keywords: "Nexus Blitz tier list, Lol Nexus Blitz tier list",
      url: "https://u.gg/lol/nexus-blitz-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST]: {
      title: `ARENA TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `The only Arena Tier List you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best Arena champions for Patch {patch}.`,
      keywords: "Arena tier list, Lol Arena tier list",
      url: "https://u.gg/lol/arena-tier-list",
    },
    [SPECIAL_TIER_LIST_PAGES.ARENA_DUO_TIER_LIST]: {
      title: `ARENA DUO TIER LIST - LEAGUE OF LEGENDS PATCH {patch}`,
      description: `The only Arena Duos Tier List you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best Arena champion duos for Patch {patch}.`,
      keywords: "Arena Duo tier list, Lol Arena Duo tier list",
      url: "https://u.gg/lol/arena-duo-tier-list",
    },
    [TIER_LIST_PAGES.DUO_TIER_LIST]: {
      title: `U.GG Duo Tier List - Patch {patch} Best Champion Duos in League of Legends`,
      description: `The only Duo Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champion duos in bot and mid jungle for Patch {patch}.`,
      url: "https://u.gg/lol/duo-tier-list",
    },
    [TIER_LIST_PAGES.COMBAT_TIER_LIST]: {
      title: `U.GG Combat Tier List - Patch {patch} Best Combat Champions in League of Legends`,
      description: `The only Combat Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions by combat metrics for Patch {patch}.`,
      url: "https://u.gg/lol/combat",
    },
    [TIER_LIST_PAGES.OBJECTIVES_TIER_LIST]: {
      title: `U.GG Objectives Tier List - Patch {patch} Best Objectives Champions in League of Legends`,
      description: `The only Objectives Tier list you need for the newest patch. Always up-to-date, U.GG takes a data science approach to the best champions by objective control for Patch {patch}.`,
      url: "https://u.gg/lol/objectives",
    },
    champions: {
      title: `${SEASON_TEXT} - LoL Champion Builds, Runes, Pro Builds, Counters Guides`,
      description:
        `Find the best lol league of legends champs champions statistics, win rates, builds, ` +
        `runes, pro builds probuilds, counters, matchups, items,` +
        `spells and abilities, and duos guides as roles top, jungle, mid, bot, support ` +
        `on ranked solo/duo/flex, aram, and normal blind/draft. ` +
        `${DESCRIPTION_KEYWORDS_SUFFIX}`,
      url: "https://u.gg/lol/champions",
    },
    champion_leaderboards: {
      title: `Best {champion} Players in {region} - U.GG`,
      description: `{region}'s best {champion} players, one-tricks, pros, and more. U.GG's Champion Leaderboard for all regions in D2+.`,
      url: "https://u.gg/lol/champion-leaderboards",
    },
    [CHAMPION_PAGES.OVERVIEW]: {
      title: `{champion} Build with Highest Winrate - LoL Runes, Items, and Skill Order`,
      description: `{champion} build with the highest winrate runes and items in every role. U.GG analyzes millions of LoL matches to give you the best LoL champion build. Patch {patch}`,
      keywords: `{champion} build, {champion} runes`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
    },
    [`${CHAMPION_PAGES.OVERVIEW}_${Multibuilds.AP}` as const]: {
      title: `AP {champion} Build and Runes - U GG`,
      description: `AP {champion} with U.GG's best data for every build. The highest win rate AP {champion} build, from rune set to skill order to item path, for {role}. LoL Patch {patch}`,
      keywords: `AP {champion} build, AP {champion} runes`,
      url: "https://u.gg/lol/champions/ap/LOWER_CHAMPION_ID/build",
    },
    champion_overview_region: {
      tr_tr: {
        title: `{champion} Build - U.GG LoL {champion} Rün`,
        description: `U.GG {champion} build, en iyi {champion} rünler kazanma oranı ve seçilme oranı'na göre gösterir. Yetenek sırası, çekirdek eşyaları ve oyun sonu eşyalarıyla, bu {champion} build, League S10 {patch} için eksiksiz bir LoL {champion} {role} rün verir.`,
        keywords: `{champion} build, {champion} Rün`,
        url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/build",
      },
    }, // as {[key:string]: { title: string, description: string, keywords: string, url: string }},
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM]: {
      title: `{champion} ARAM Build - U.GG {champion} Runes + Item Guide for ARAM`,
      description: `U.GG {champion} ARAM build shows best {champion} ARAM runes by WR and popularity. With skill order and items, this {champion} guide offers a full LoL {champion} ARAM build for Patch {patch}`,
      keywords: `{champion} ARAM build, {champion} runes`,
      url: "https://u.gg/lol/champions/aram/LOWER_CHAMPION_ID-aram",
    },
    [`${SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM}_${Multibuilds.AP}` as const]: {
      title: `AP {champion} ARAM Build - U.GG AP {champion} Runes + Item Guide ARAM`,
      description: `U.GG AP {champion} ARAM shows best AP {champion} runes by WR and popularity. Get AP {champion} skill order and items, plus other ARAM builds, for LoL Patch {patch}`,
      keywords: `AP {champion} ARAM build, AP {champion} runes`,
      url: "https://u.gg/lol/champions/aram/ap/LOWER_CHAMPION_ID-aram",
    },
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF]: {
      title: `{champion} ARURF Build - U.GG {champion} Runes + Item Guide for ARURF`,
      description: `{champion} ARURF Build - Find the best {champion} Runes and Items for ARURF. U.GG analyzes millions of ARURF matches to give you the best {champion} ARURF guide. Patch {patch}`,
      keywords: `{champion} ARURF build, {champion} runes`,
      url: "https://u.gg/lol/champions/arurf/LOWER_CHAMPION_ID-arurf",
    },
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_URF]: {
      title: `{champion} URF Build - U.GG {champion} Runes + Item Guide for URF`,
      description: `{champion} URF Build - Find the best {champion} Runes and Items for URF. U.GG analyzes millions of URF matches to give you the best {champion} URF guide. Patch {patch}`,
      keywords: `{champion} URF build, {champion} runes`,
      url: "https://u.gg/lol/champions/urf/LOWER_CHAMPION_ID-urf",
    },
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL]: {
      title: `{champion} One for All Build - U.GG {champion} Runes + Item Guide for One for All`,
      description: `{champion} One for All Build - Find the best {champion} Runes and Items for One for All. U.GG analyzes millions of One for All matches to give you the best {champion} One for All guide. Patch {patch}`,
      keywords: `{champion} One for All build, {champion} runes`,
      url: "https://u.gg/lol/champions/one-for-all/LOWER_CHAMPION_ID-one-for-all",
    },
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ]: {
      title: `{champion} Nexus Blitz Build - U.GG {champion} Runes + Item Guide for Nexus Blitz`,
      description: `{champion} Nexus Blitz Build - Find the best {champion} Runes and Items for Nexus Blitz. U.GG analyzes millions of Nexus Blitz matches to give you the best {champion} Nexus Blitz guide. Patch {patch}`,
      keywords: `{champion} Nexus Blitz build, {champion} runes`,
      url: "https://u.gg/lol/champions/nexus-blitz/LOWER_CHAMPION_ID-nexus-blitz",
    },
    [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA]: {
      title: `{champion} Arena Build - Best Items, Augments, Duos`,
      description: `{champion} Arena Build with the best items, augments, and duos. U.GG analyzes millions of matches to give you the best {champion} Arena guide. Patch {patch}`,
      keywords: `{champion} Arena build, {champion} augments`,
      url: "https://u.gg/lol/champions/arena/LOWER_CHAMPION_ID-arena-build",
    },
    [CHAMPION_PAGES.ITEMS]: {
      title: `${SEASON_TEXT} - Best {champion} Items Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} items guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/items",
    },
    [CHAMPION_PAGES.ITEM_PATHS]: {
      title: `${SEASON_TEXT} - Best {champion} Items Paths Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} items paths guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/item-paths",
    },
    [CHAMPION_PAGES.SPELLS_ABILITIES]: {
      title: `${SEASON_TEXT} - Best {champion} Spells & Abilities Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} spells and abilities guide. ` +
        `Top, jungle, mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/spells-abilities",
    },
    [CHAMPION_PAGES.RUNES_TABLE]: {
      title: `{champion} {role} Runes Table for ${SEASON_TEXT} :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} runes guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/runes-table",
    },
    [CHAMPION_PAGES.RUNE_SETS]: {
      title: `{champion} {role} Runes Sets for ${SEASON_TEXT} :: U.GG`,
      description:
        `Find the best League of Legends champion {champion} rune sets guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/rune-sets",
    },
    [CHAMPION_PAGES.COUNTERS]: {
      title: `{champion} Counter - Best Counters from Best Data LoL Patch {patch} - U.GG`,
      description: `{champion} Counter. Best Counter Picks from the Best Data. Riot-approved U.GG analyzes millions of LoL matches. Sort by role, rank, region. Patch {patch}`,
      keywords: `{champion} counter`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/counter",
    },
    [CHAMPION_PAGES.MATCHUPS]: {
      title: `${SEASON_TEXT} - Best {champion} Matchups Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} matchups guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, ranked flex, and normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/matchups",
    },
    [CHAMPION_PAGES.DUOS]: {
      title: `${SEASON_TEXT} - Best {champion} Duos Builds Guides :: U.GG`,
      description:
        `Find the best League of Legends {champion} duos guide. Top, jungle, ` +
        `mid, bot, support roles on ranked solo/duo/flex, aram, normal blind/draft.` +
        ` ${DESCRIPTION_KEYWORDS_SUFFIX}.`,
      url: "https://u.gg/lol/champions/LOWER_CHAMPION_ID/duos",
    },
    items: {
      title: `Full Items Catalog | League of Legends Wiki`,
      description: `All in-game items for League of Legends, including legendary, starting items, and consumables. Up-to-date stats, passives, actives, and gold costs.`,
      url: "https://u.gg/lol/items",
    },
    item_overview: {
      title: `{itemName} | {itemType} Item | League of Legends Wiki`,
      description: `{itemDescription}`,
      keywords: `{itemName}`,
      url: "https://u.gg/lol/items/LOWER_ITEM_NAME",
    },
  };
};

export function useChampionProfileSEO() {
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const seoPages = useSeoPages();

  type ChampionProfileSEOParams = {
    champion?: { name: string; key: number };
    patch?: string;
    region?: string;
    language?: string;
    role?: string;
    multi?: string;
  };
  return (
    page: CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES,
    { champion, patch, region, language, role, multi }: ChampionProfileSEOParams,
  ) => {
    let seoName: keyof typeof seoPages = page;
    if (
      page === CHAMPION_PAGES.OVERVIEW &&
      language &&
      seoPages["champion_overview_region"][language.toLowerCase() as keyof (typeof seoPages)["champion_overview_region"]]
    ) {
      seoName = "champion_overview_region";
    }
    if (page === CHAMPION_PAGES.OVERVIEW || page === SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM) {
      if (multi === Multibuilds.AP) {
        seoName = `${page}_${multi}`;
      }
    }

    return seoName ? getPageTitleAndDesc(seoName, { champion, patch, region, language, role }) : null;
  };
}

export function useItemSEO({ ssr }: { ssr?: boolean }) {
  const { getItemImg, getItemType, useNewRiotItems, getItemIdFromName, useItemsWiki } = getRiotAssetsContext();
  const seoPages = useSeoPages();
  const { data: items, loading: fetchingItems, error: errorItems } = useNewRiotItems({ ssr });
  const { data: itemsWiki } = useItemsWiki({ ssr });

  return (itemName: string) => {
    const normalizedItemName = itemName.replace(/'/g, "").replace(/ /g, "-").toLowerCase();
    const itemId = getItemIdFromName(normalizedItemName, { optionalData: items });
    const itemData = items && itemId && items[itemId];

    if (itemId && itemData && itemsWiki) {
      let { title, description, keywords, url } = seoPages["item_overview"];
      const originalName = itemData["name"];
      const itemDescription = getItemDescription(itemId, itemData, itemsWiki, { render: false });
      const itemType = getItemType(itemId, itemsWiki);
      title = title.replace("{itemName}", originalName);
      title = title.replace("{itemType}", itemType);
      description = description.replace(
        "{itemDescription}",
        typeof itemDescription === "string" ? itemDescription : renderToString(itemDescription),
      );
      const imgUrl = getItemImg(itemId);
      const metaImgSrc = imgUrl;
      keywords = keywords.replace("{itemName}", originalName);
      url = url.replace("LOWER_ITEM_NAME", normalizedItemName);

      return <MetaHeader title={title} url={url} description={description} metaImgSrc={metaImgSrc} keywords={keywords} />;
    }

    return null;
  };
}

export function usePageTitleAndDesc() {
  const { getChampionImg, getNormalizedChampionName } = getRiotAssetsContext();
  const seoPages = useSeoPages();

  const roleTextMap: Record<string, string> = {
    adc: "ADC",
    support: "Support",
    middle: "Mid",
    mid: "Mid",
    top: "Top",
    jungle: "Jungle",
  };

  type PageTitleAndDescParams = {
    champion?: { name: string; key: number };
    patch?: string;
    region?: string;
    language?: string;
    role?: string;
  };
  return (page: keyof typeof seoPages, params?: PageTitleAndDescParams) => {
    const { champion, patch, region, language, role } = params || {};
    let championName, lowerId;
    let pageData = seoPages[page];

    if (page === "champion_overview_region") {
      const regionPageData =
        language && seoPages[page][language.toLowerCase() as keyof (typeof seoPages)["champion_overview_region"]];
      if (regionPageData) {
        pageData = regionPageData;
      }
    }

    if (!pageData) {
      return null;
    }

    let title = "title" in pageData ? pageData.title : "";
    let description = "description" in pageData ? pageData.description : "";
    let keywords = "keywords" in pageData ? pageData.keywords : "";
    let url = "url" in pageData ? pageData.url : "";
    let metaImgSrc = "https://static.bigbrain.gg/assets/lol/icons/ugg_meta_128x128.jpg";

    if (champion) {
      championName = champion.name;
      lowerId = getNormalizedChampionName(champion.key);
      const imgUrl = getChampionImg(champion.key);
      metaImgSrc = imgUrl;
      title = title.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      description = description.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      keywords = keywords && keywords.replace(new RegExp(REPLACE_CHAMPION, "g"), championName);
      url = url.replace(new RegExp(REPLACE_LOWER_ID, "g"), lowerId);
    }
    if (patch) {
      const patchSplit = patch.split(/_|\./g);
      const seoPatch = `${patchSplit[0]}.${patchSplit[1]}`;
      title = title.replace(new RegExp("{patch}", "g"), seoPatch);
      description = description.replace(new RegExp("{patch}", "g"), seoPatch);
    }
    if (role) {
      const roleText = roleTextMap[role];
      title = title.replace(new RegExp(REPLACE_ROLE, "g"), roleText);
      description = description.replace(new RegExp(REPLACE_ROLE, "g"), roleText);
    }
    if (region) {
      title = title.replace(new RegExp(REPLACE_REGION, "g"), region);
      description = description.replace(new RegExp(REPLACE_REGION, "g"), region);
    }

    return <MetaHeader title={title} url={url} description={description} metaImgSrc={metaImgSrc} keywords={keywords} />;
  };
}

interface MetaHeaderProps {
  title: string;
  url: string;
  description: string;
  metaImgSrc?: string;
  keywords?: string;
}

function MetaHeader(props: MetaHeaderProps) {
  const { title, url, description, metaImgSrc, keywords } = props;

  const defaultImgSrc = "https://static.bigbrain.gg/assets/lol/icons/ugg_meta_128x128.jpg";

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={metaImgSrc || defaultImgSrc} />
    </Helmet>
  );
}
