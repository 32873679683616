import { Link } from "react-router-dom";
import { Item } from "@outplayed/tooltips";
import type { ItemProps } from "@outplayed/tooltips";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

export function ItemLink(props: ItemProps) {
  return (
    <Item {...props}>
      {(jsx, { itemId, normalizedItemName }) => <Link to={`${AppRoutes.ITEMS_DIRECTORY}/${normalizedItemName}`}>{jsx}</Link>}
    </Item>
  );
}
