import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { Checkbox } from "@ugg/shared/components/common/Checkbox";
import ButtonSuccess from "components/Buttons/ButtonSuccess";

import { useLogin } from "lib/hooks/userHooks";
import { parseQueryString } from "@ugg/shared/utils/url-query-params";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

const Login = () => {
  const location = useLocation();
  const search = location.search.substring(1);
  const parsedQueryString = parseQueryString(search);
  const [loginError, setLoginError] = useState("");
  const [loggingIn] = useGlobal("loggingIn");
  // Form
  const [form, setForm] = useState({
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    persistLogin: { value: true },
  });

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const { email, password, persistLogin } = form;

  const loginCreds = {
    persistLogin: persistLogin.value,
    email: email.value,
    password: password.value,
  };

  const onCompleted = () => {
    let redirectURL = AppRoutes.HOME;
    if (parsedQueryString.continue) {
      const splitQuery = parsedQueryString.continue.split("/");
      redirectURL = `${AppRoutes.PREMIUM_CHECKOUT}?planType=${splitQuery.pop()}`;
    }
    if (parsedQueryString.redirect_url) {
      redirectURL = parsedQueryString.redirect_url;
    }
    window.location.href = redirectURL;
  };

  const onError = (error) => {
    // Bad email or password
    if (error === "unauthorized") {
      setLoginError("Incorrect email/password");
    }
    // Other errors
    else {
      setLoginError("An unexpected error occurred. Please try again.");
    }
  };

  const login = useLogin(loginCreds, onCompleted, onError);

  const onInputChange = (key, value) => {
    setForm({ ...form, [key]: { ...form[key], ...value } });
  };

  const verifyForm = () => {
    let verifiedForm = {};
    let hasError = false;
    function mergeForm(state, error) {
      if (error === true) {
        hasError = true;
      }
      return Object.assign(verifiedForm, state);
    }

    if (email.value !== undefined) {
      if (email.value.trim().length === 0) {
        verifiedForm = mergeForm({ email: { ...email, error: "Missing email" } }, true);
      } else {
        verifiedForm = mergeForm({ email: { ...email, error: "" } });
      }
    }

    // Password
    if (password.value.trim().length === 0) {
      verifiedForm = mergeForm({ password: { ...password, error: "Missing password" } }, true);
    } else {
      verifiedForm = mergeForm({ password: { ...password, error: "" } });
    }

    return { form: verifiedForm, error: hasError };
  };

  const completeLogin = (e) => {
    const verifiedForm = verifyForm();
    setForm({ ...form, ...verifiedForm.form });

    if (verifiedForm.error) return;

    setLoginError("");
    login();
  };

  const switchToSignup = () => {
    let continuePath = [];
    if (parsedQueryString.continue) {
      continuePath.push(`continue=${parsedQueryString.continue}`);
    }
    if (parsedQueryString.redirect_url) {
      continuePath.push(`redirect_url=${parsedQueryString.redirect_url}`);
    }

    return { pathname: AppRoutes.SIGNUP, search };
  };

  return (
    <div className="content-side-padding w-full mt-[100px]">
      <Helmet>
        <title>U.GG - Login</title>
      </Helmet>
      <div className="w-full max-w-[500px] mx-auto">
        <div className="flex mb-[8px] text-[14px] text-lavender-50">
          <Link to={AppRoutes.HOME}>&lt; Back to Home</Link>
        </div>
        <div className="w-full rounded-[6px] p-[32px] max-xs:p-[24px] bg-purple-200">
          <Link className="flex mb-[24px]" to={AppRoutes.HOME}>
            <img className="w-[200px]" src="https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg" />
          </Link>
          <div className="mb-[12px] text-white text-[30px] max-xs:text-[24px] font-medium font-barlow">Welcome back to U.GG</div>
          <div className="mb-[24px] text-lavender-50 text-[16px] font-inter">
            New here?{" "}
            <Link className="underline text-accent-blue-400" to={switchToSignup()}>
              <span>Create an account</span>
            </Link>
          </div>
          <form onSubmit={(e) => e.preventDefault()} className="w-full">
            <CustomInput
              className="
                mb-[24px] [&_input]:h-[42px]
                [&_input]:text-[16px] [&_input]:text-white [&_input]:rounded-[3px] [&_input]:border-[1px] [&_input]:border-[#31334a]
                [&_input]:bg-purple-100 [&_input:focus]:outline-none [&_input:focus]:border-[#919496]
                [&_input]:placeholder:text-lavender-50 [&_input]:placeholder:font-normal
                [&_.input-label]:text-[14px] [&_.input-label]:mb-[10px] [&_.input-label]:text-lavender-50 [&_.input-label]:font-inter
              "
              value={email.value}
              error={email.error}
              onChange={(e) => onInputChange("email", { value: e.target.value })}
              placeholder="Email"
              type="email"
              tabIndex={1000}
              autoFocus
              label={"Email"}
            />
            <CustomInput
              className="
                [&_input]:h-[42px]
                [&_input]:text-[16px] [&_input]:text-white [&_input]:rounded-[3px] [&_input]:border-[1px] [&_input]:border-[#31334a]
                [&_input]:bg-purple-100 [&_input:focus]:outline-none [&_input:focus]:border-[#919496]
                [&_input]:placeholder:text-lavender-50 [&_input]:placeholder:font-normal
                [&_.input-label]:text-[14px] [&_.input-label]:mb-[10px] [&_.input-label]:text-lavender-50 [&_.input-label]:font-inter
              "
              value={password.value}
              error={password.error}
              onChange={(e) => onInputChange("password", { value: e.target.value })}
              placeholder="Password"
              type="password"
              tabIndex={1000}
              label={"Password"}
              rightLabel={
                <Link to={AppRoutes.RECOVER_ACCOUNT} className="text-[14px] text-accent-blue-400 hover:underline">
                  Forgot Password?
                </Link>
              }
            />
            <div className="flex items-center my-[24px]">
              <Checkbox
                checkboxId="keep-me-logged-in"
                isChecked={persistLogin.value}
                onChange={(e) => onInputChange("persistLogin", { value: e.target.checked })}
              />
              <label className="ml-[8px] text-[16px] text-white leading-normal cursor-pointer" htmlFor="keep-me-logged-in">
                Keep me logged in
              </label>
            </div>
            {loginError && (
              <div className="flex items-center justify-center w-full h-[42px] mb-[24px] rounded-[3px] text-white font-bold text-[14px] bg-[orange]">
                {loginError}
              </div>
            )}
            <ButtonSuccess
              className="btn-blue h-[42px] !text-[14px]"
              label="Login"
              isLoading={loggingIn}
              onClick={completeLogin}
              bForceSubmitOnEnter={true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
