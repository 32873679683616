import { RoleS, getRoleSVG } from "@ugg/shared/utils/role-helpers";
import { SPECIAL_TIER_LIST_PAGES, TIER_LIST_PAGES } from "@ugg/shared/pages/tier-list-pages";
import { REGION_OPTIONS, RANK_OPTIONS_WITH_D2, ALL_CHAMPIONS_OPTIONS } from "../common";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RegionS } from "@ugg/shared/utils/region-helpers";

// ------------------------------------
// TIER LIST
// ------------------------------------

export const TIER_LIST_QUEUE_TYPE_OPTIONS = [
  { value: QueueTypeS.RANKED_SOLO, label: "Ranked Solo", path: AppRoutes.DEFAULT_TIER_LIST },
  {
    value: QueueTypeS.ARAM,
    label: "ARAM",
    path: AppRoutes.ARAM_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST,
  },
  { value: QueueTypeS.RANKED_FLEX, label: "Ranked Flex", path: AppRoutes.DEFAULT_TIER_LIST },
  { value: QueueTypeS.QUICKPLAY, label: "Quickplay", path: AppRoutes.DEFAULT_TIER_LIST },
  { value: QueueTypeS.SWIFTPLAY, label: "Swiftplay", path: AppRoutes.DEFAULT_TIER_LIST },
  { value: QueueTypeS.NORMAL_DRAFT, label: "Normal Draft", path: AppRoutes.DEFAULT_TIER_LIST },
  {
    value: QueueTypeS.NEXUS_BLITZ,
    label: "Nexus Blitz",
    path: AppRoutes.NEXUS_BLITZ_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST,
  },
  {
    value: QueueTypeS.PICK_URF,
    label: "URF",
    path: AppRoutes.PICK_URF_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST,
  },
  {
    value: QueueTypeS.ARURF,
    label: "ARURF",
    path: AppRoutes.ARURF_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST,
  },
  {
    value: QueueTypeS.ONE_FOR_ALL,
    label: "One For All",
    path: AppRoutes.ONE_FOR_ALL_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST,
  },
  {
    value: QueueTypeS.ARENA,
    label: "Arena",
    path: AppRoutes.ARENA_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST,
  },
  {
    value: QueueTypeS.ARENA_DUO,
    label: "Arena Duos",
    path: AppRoutes.ARENA_DUO_TIER_LIST,
    validParamsPage: SPECIAL_TIER_LIST_PAGES.ARENA_DUO_TIER_LIST,
  },
];

export const TIER_LIST_ROLE_OPTIONS = [
  {
    value: TIER_LIST_PAGES.DEFAULT_TIER_LIST,
    label: "All Roles",
    role: RoleS.ALL,
    img: getRoleSVG(RoleS.ALL),
    path: AppRoutes.DEFAULT_TIER_LIST,
  },
  {
    value: TIER_LIST_PAGES.TOP_TIER_LIST,
    label: "Top",
    role: RoleS.TOP,
    img: getRoleSVG(RoleS.TOP),
    imgActive: getRoleSVG(RoleS.TOP, { active: true }),
    path: AppRoutes.TOP_TIER_LIST,
  },
  {
    value: TIER_LIST_PAGES.JUNGLE_TIER_LIST,
    label: "Jungle",
    role: RoleS.JUNGLE,
    img: getRoleSVG(RoleS.JUNGLE),
    imgActive: getRoleSVG(RoleS.JUNGLE, { active: true }),
    path: AppRoutes.JUNGLE_TIER_LIST,
  },
  {
    value: TIER_LIST_PAGES.MID_TIER_LIST,
    label: "Middle",
    role: RoleS.MIDDLE,
    img: getRoleSVG(RoleS.MIDDLE),
    imgActive: getRoleSVG(RoleS.MIDDLE, { active: true }),
    path: AppRoutes.MID_TIER_LIST,
  },
  {
    value: TIER_LIST_PAGES.ADC_TIER_LIST,
    label: "Bot",
    role: RoleS.BOTTOM,
    img: getRoleSVG(RoleS.BOTTOM),
    imgActive: getRoleSVG(RoleS.BOTTOM, { active: true }),
    path: AppRoutes.ADC_TIER_LIST,
  },
  {
    value: TIER_LIST_PAGES.SUPPORT_TIER_LIST,
    label: "Support",
    role: RoleS.SUPPORT,
    img: getRoleSVG(RoleS.SUPPORT),
    imgActive: getRoleSVG(RoleS.SUPPORT, { active: true }),
    path: AppRoutes.SUPPORT_TIER_LIST,
  },
];

export const TIER_LIST_OPTIONS = {
  queueType: TIER_LIST_QUEUE_TYPE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const TIER_LIST_DEFAULT = {
  queueType: TIER_LIST_OPTIONS.queueType[0].value,
  region: TIER_LIST_OPTIONS.region[0].value,
  rank: TIER_LIST_OPTIONS.rank[1].value,
  patch: TIER_LIST_OPTIONS.patch.options,
  allChampions: "false",
};

export const SPECIAL_TIER_LIST_DEFAULT = {
  region: RegionS.WORLD,
  patch: TIER_LIST_OPTIONS.patch.options,
  allChampions: "true",
};
