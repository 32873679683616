import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default class CustomInput extends Component {
  render() {
    const { className, error, hideErrorMessage, onCreateRef, children, label, rightLabel, ...inputProps } = this.props;

    return (
      <div className={classNames("custom-input-container", className)}>
        <div className="input-label-container">
          {label && <div className="input-label">{label}</div>}
          {rightLabel}
        </div>
        {error && <div className={`input-error-msg ${hideErrorMessage ? "input-error-msg_hide" : ""}`}>{error}</div>}
        <div style={{ position: "relative" }}>
          <input ref={onCreateRef} {...inputProps} />
          {children}
        </div>
      </div>
    );
  }
}
