import { window } from "global";
import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useGlobal } from "reactn";
import SearchBar from "components/SearchBar";
import NotificationContainer from "./Masthead/Notifications/NotificationContainer";
import SettingsContainer from "./Masthead/Settings/views/SettingsContainer";
import { useLoginState } from "lib/hooks/userHooks";
import { ReactComponent as BlueManPlus } from "svg/blue-man-plus.svg";
import { ReactComponent as StarsThree } from "svg/stars-three.svg";
import { ReactComponent as SearchIconWhite } from "svg/search-icon-2-white.svg";
import { ReactComponent as Hamburger } from "svg/hamburger.svg";
import { ReactComponent as ULogo } from "svg/logos/ugg-u-logo.svg";
import FeedbackIcon from "./Masthead/Feedback/FeedbackIcon";
import AppDownloadButton from "./Buttons/AppDownloadButton";
import { useUserPremiumState } from "@ugg/shared/api/requests/accounts/user-state";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

const Masthead = (props: { sideNavOpen: boolean; setSideNavOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { sideNavOpen, setSideNavOpen } = props;
  const location = useLocation();
  const isLoggedIn = useLoginState();
  const [loggingIn] = useGlobal("loggingIn");
  const premiumState = useUserPremiumState();
  const isLanding = location.pathname === AppRoutes.HOME;

  return (
    <div
      id="masthead" // DO NOT REMOVE ID. REQUIRED FOR ADS!!!!
      className={classNames("mt-[31px] h-[calc(var(--masthead-height)-1px)]", {
        "!h-0 max-sm:!h-[calc(var(--masthead-height)-1px)]": isLanding,
      })}
    >
      <div
        className={classNames(
          "masthead-container", // DO NOT REMOVE 'masthead-container' class. REQUIRED FOR ADS!!!!
          `flex items-center fixed top-[var(--game-nav-height)] right-0 z-[9999999] px-[24px] w-[calc(100%-64px)] 
          h-[var(--masthead-height)] bg-purple-500 border-b-[1px] border-b-purple-550 max-md:px-[18px] max-md:w-full`,
          {
            "sm:!bg-transparent sm:!border-transparent": isLanding,
          },
        )}
      >
        {window && (
          <>
            <Hamburger
              className="p-[4px] w-[24px] cursor-pointer md:hidden group [&_path]:hover:fill-white"
              onClick={() => setSideNavOpen(!sideNavOpen)}
            />
            <Link to={AppRoutes.HOME} className="flex-center md:hidden">
              <ULogo className="w-[20px] ml-[20px]" />
            </Link>
            {location.pathname !== AppRoutes.UGG_APP_SPLASH && (
              <AppDownloadButton
                className="max-md:hidden"
                url={`${AppRoutes.UGG_APP_SPLASH}?utm_source=uggtop`}
                label="Download Now"
                icon={"cloud"}
              />
            )}
            <div className="flex-1 flex items-center justify-center px-[12px] max-xs:hidden">
              {!([AppRoutes.HOME, AppRoutes.LIVE_GAMES] as string[]).includes(location.pathname.toLowerCase()) && (
                <SearchBar theme="dark" placeholder={"Search Summoner or Champion"} isMasthead />
              )}
            </div>
            <div className={"flex items-center justify-center ml-auto"}>
              <SearchIconWhite className="w-[16px] h-[16px] xs:hidden" onClick={() => setSideNavOpen(!sideNavOpen)} />
              <SettingsContainer />
              <FeedbackIcon />
              <div className="max-xs:hidden">
                <NotificationContainer />
              </div>
              {!loggingIn && !isLoggedIn && (
                <>
                  <Link to={AppRoutes.LOGIN} className="btn-blue btn-blue_hover h-[40px] w-[100px] ml-[18px] max-sm:hidden">
                    Log In
                  </Link>
                  <Link
                    to={AppRoutes.LOGIN}
                    className="flex items-center justify-center w-[28px] h-[28px] ml-[12px] p-[5px] rounded-[2px] bg-accent-blue-400 hover:bg-accent-blue-600 cursor-pointer sm:hidden"
                  >
                    <BlueManPlus className="w-[16px] h-[16px]" />
                  </Link>
                </>
              )}
              {!loggingIn && !premiumState.loading && !premiumState.data?.isPremium && isLoggedIn && (
                <Link
                  to={AppRoutes.SETTINGS_PREMIUM}
                  className="btn-blue btn-blue_hover ml-[12px] h-[40px] text-[13px] font-bold max-sm:hidden"
                >
                  <StarsThree className="mr-[6px] w-[16px] h-[16px]" />
                  Go Ad-Free
                </Link>
              )}
              {!loggingIn && !premiumState.loading && premiumState.data?.isPremium && isLoggedIn && (
                <Link
                  to={AppRoutes.SETTINGS_PREMIUM}
                  className="w-[28px] h-[28px] ml-[12px] p-[5px] rounded-[2px] hover:bg-accent-blue-600 cursor-pointer"
                >
                  <StarsThree className="w-[16px] h-[16px]" />
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Masthead;
