// RESPONSIVE
export const DEFAULT_BREAKPOINT = "DESKTOP_LARGE";
export const WINDOW_BREAKPOINTS_RANGE: Record<string, [number, number]> = {
  MOBILE_SMALL: [0, 399], // xxxs
  MOBILE_MEDIUM: [400, 599], // xxs
  MOBILE_LARGE: [600, 899], // xs
  TABLET: [900, 1139], // sm
  DESKTOP_SMALL: [1140, 1359], // md
  DESKTOP_MEDIUM: [1360, 1469], // lg
  DESKTOP_LARGE: [1470, 9999999999], // xl
};

// "xxxs": "360px",
// "xxs": "400px",
// "xs": "600px",
// "sm": "900px",
// "md": "1140px",
// "lg": "1360px",
// "xl": "1920px"

// JSON FETCHER CACHE KEY
export const OVERVIEW_EMERALD_PLUS_WORLD = "overview_emerald_plus_world";
export const OVERVIEW_EMERALD_PLUS_WORLD_AP = "overview_emerald_plus_world_ap";
export const OVERVIEW_EMERALD_PLUS_WORLD_TANK = "overview_emerald_plus_world_tank";
export const OVERVIEW_EMERALD_PLUS_WORLD_AD = "overview_emerald_plus_world_ad";
export const OVERVIEW_EMERALD_PLUS_WORLD_CRIT = "overview_emerald_plus_world_crit";
export const OVERVIEW_EMERALD_PLUS_WORLD_LETHALITY = "overview_emerald_plus_world_lethality";
export const OVERVIEW_EMERALD_PLUS_WORLD_ONHIT = "overview_emerald_plus_world_onhit";
export const OVERVIEW_EMERALD_PLUS_WORLD_CT = "overview_emerald_plus_world_ct";
export const RANKINGS_EMERALD_PLUS_WORLD = "rankings_emerald_plus_world";

export const APP_BUILD_TYPE = [
  "build_local",
  "app",
  "build_staging_mac",
  "build_staging_win",
  "build_prod_mac",
  "build_prod_win",
];
