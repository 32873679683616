import gql from "graphql-tag";
import { PlayerInfo } from "../requests/summoner-profiles/profile-init";

export const LolSettings = `
  summoners {
    isDefault
    regionId
    riotTagLine
    riotUserName
    verified
    iconUrl
    iconId
  }
  favoriteChampions {
    championId
  }
  favoriteSummoners {
    regionId
    riotTagLine
    riotUserName
    iconUrl
    iconId
  }
`;

export const URL = gql`
  query Url($url: String!, $anonId: String) {
    url(url: $url, anonId: $anonId) {
      success
    }
  }
`;

export const GET_CLIENT_TOKEN = gql`
  query {
    getBraintreeClientToken {
      clientToken
      success
    }
  }
`;

export const START_BRAINTREE_SUBSCRIPTION = gql`
  mutation StartBraintreeSubscription(
    $planDurationMonths: Int!
    $paymentMethodNonce: String
    $recaptchaToken: String!
    $recaptchaVersion: String!
  ) {
    premiumSignupPayments(
      planDurationMonths: $planDurationMonths
      payment_method_nonce: $paymentMethodNonce
      recaptcha_token: $recaptchaToken
      recaptcha_version: $recaptchaVersion
    ) {
      balance
      billingDayOfMonth
      billingPeriodEndDate
      billingPeriodStartDate
      failureCount
      id
      nextBillingDate
      price
      status
      updatedAt
    }
  }
`;

export const UPDATE_BRAINTREE_SUBSCRIPTION_PAYMENT = gql`
  mutation UpdateBraintreeSubscriptionPayment($paymentMethodNonce: String) {
    updateSubscriptionPayment(payment_method_nonce: $paymentMethodNonce) {
      balance
      billingDayOfMonth
      billingPeriodEndDate
      billingPeriodStartDate
      failureCount
      id
      nextBillingDate
      price
      status
      updatedAt
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation Register(
    $email: String!
    $password: String!
    $marketingOptOut: Boolean!
    $riotUserName: String!
    $riotTagLine: String!
    $summonerRegion: String!
  ) {
    register(
      agreementSigned: true
      email: $email
      password: $password
      marketingOptOut: $marketingOptOut
      primarySummoner: { isDefault: true, riotUserName: $riotUserName, riotTagLine: $riotTagLine, regionId: $summonerRegion }
    ) {
      token
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteUser($email: String!, $password: String!) {
    deleteUser(email: $email, password: $password) {
      success
    }
  }
`;

export const CHECK_EMAIL = gql`
  query CheckEmail($email: String!, $recaptchaToken: String, $recaptchaTokenV3: String) {
    checkEmail(email: $email, recaptchaToken: $recaptchaToken, recaptchaTokenV3: $recaptchaTokenV3) {
      success
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query ForgotPassword($email: String!, $debug: String) {
    forgotPassword(email: $email, debug: $debug) {
      success
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyUser($verificationCode: String!) {
    verify(verificationCode: $verificationCode) {
      success
    }
  }
`;

export const REVERIFY_EMAIL = gql`
  query Reverify($debug: String) {
    reverifyEmail(debug: $debug) {
      success
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($newPassword: String!, $password: String, $passwordResetHash: String) {
    updatePassword(newPassword: $newPassword, password: $password, passwordResetHash: $passwordResetHash) {
      success
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings(
    $day: Int
    $month: Int
    $year: Int
    $favoriteSummoners: [InputSummoner]
    $favoriteChampions: [InputChampion]
    $filterTierList: InputFilterTierList
    $filterBuildsAndRunes: InputFilterBuildsAndRunes
    $firstName: String
    $lastName: String
    $marketingOptOut: Boolean
    $startDay: Int
    $startMonth: Int
    $startYear: Int
    $primarySummoner: InputSummoner
  ) {
    updateSettings(
      birthdayDay: $day
      birthdayMonth: $month
      birthdayYear: $year
      favoriteSummoners: $favoriteSummoners
      favoriteChampions: $favoriteChampions
      filterTierList: $filterTierList
      filterBuildsAndRunes: $filterBuildsAndRunes
      firstName: $firstName
      lastName: $lastName
      marketingOptOut: $marketingOptOut
      startDay: $startDay
      startMonth: $startMonth
      startYear: $startYear
      primarySummoner: $primarySummoner
    ) {
      birthdayDay
      birthdayMonth
      birthdayYear
      email
      firstName
      lastName
      marketingOptOut
      startDay
      startMonth
      startYear
      billingSettings {
        creditCards {
          addressCity
          addressCountry
          addressLineOne
          addressLineTwo
          addressState
          addressZip
          brand
          expMonth
          expYear
          isDefault
          last4
          name
        }
      }
      lolSettings {
        ${LolSettings}
      }
      filterDefaults {
        filterTierList {
          mmr
          queueType
          regionId
          role
          showAllChampions
        }
        filterBuildsAndRunes {
          mmr
          queueType
          regionId
        }
      }
    }
  }
`;

export const RESUBSCRIBE_USER = gql`
  mutation {
    resubscribeUser {
      success
    }
  }
`;

export const UNSUBSCRIBE_USER = gql`
  mutation {
    unsubscribeUser {
      success
    }
  }
`;

export const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      firstName
      lastName
      isVerified
      googleAnalyticsId
      loginState {
        token
      }
      lolSettings {
        ${LolSettings}
      }
      filterDefaults {
        filterTierList {
          mmr
          queueType
          regionId
          role
          showAllChampions
        }
        filterBuildsAndRunes {
          mmr
          queueType
          regionId
        }
      }
      premium {
        isPremium
        premiumVersion
        hasFailedPayment
        paymentFailureDate
      }
    }
  }
`;

export const LOGOUT = gql`
  query {
    logout {
      success
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query {
    getSettings {
      birthdayDay
      birthdayMonth
      birthdayYear
      email
      firstName
      lastName
      marketingOptOut
      startDay
      startMonth
      startYear
      lolSettings {
        ${LolSettings}
      }
      filterDefaults {
        filterTierList {
          mmr
          queueType
          regionId
          role
          showAllChampions
        }
        filterBuildsAndRunes {
          mmr
          queueType
          regionId
        }
      }
      billingSettings {
        creditCards {
          last4
          brand
          expMonth
          expYear
          isDefault
          name
          addressLineOne
          addressLineTwo
          addressCity
          addressState
          addressZip
          addressCountry
        }
        paypal {
          email
        }
      }
    }
  }
`;

export const GET_BILLING_DATA = gql`
  query {
    getBillingData {
      invoices {
        currency
        paid
        brand
        last4
        periodStart
        periodEnd
        totalPaid
      }
      subscription {
        cancelAtPeriodEnd
        currentPeriodEnd
        status
        isBraintree
      }
      upcomingInvoice {
        chargeAmount
        subtotal
        error
        nextPaymentAttempt
      }
    }
  }
`;

export const GET_PREMIUM_PLAN_PRICES = gql`
  query {
    getPlanPrices {
      amount
      currency
      planDuration
    }
  }
`;

export const GET_INDIV_CHAMPION = gql`
  query getProfileChampion(
    $championId: String!
    $queueType: String!
    $regionId: String!
    $role: String!
    $seasonId: String!
    $riotUserName: String!
    $riotTagLine: String!
  ) {
    profileChampion(
      championId: $championId
      queueType: $queueType
      regionId: $regionId
      role: $role
      seasonId: $seasonId
      riotUserName: $riotUserName
      riotTagLine: $riotTagLine
    ) {
      championStatistic {
        ccTimeDealt
        wardsPlaced
        damageMitigated
        xpAdv15
        wardsKilled
        exodiaUuid
        regionId
        pentaKills
        secondDuoCarryPercentage15
        cs
        damageTaken
        secondDuoCsAdv15
        seasonId
        duoGoldAdv15
        heals
        totalMatches
        ccTime
        role
        goldAdv15
        duoKillAdv15
        csAdv15
        tripleKills
        assists
        jungleCsAdv15
        duoCsAdv15
        secondDuoChampionId
        turrets
        carryPercentage15
        queueType
        enemyJungleCs
        secondDuoKillAdv15
        teamGoldDifference15
        totalMatchDuration
        jungleCs
        deaths
        maxKills
        duoCarryPercentage15
        doubleKills
        killAdv15
        quadraKills
        secondDuoXpAdv15
        duoXpAdv15
        gold
        damageToTurrets
        damage
        secondDuoGoldAdv15
        wins
        visionScore
        championId
        maxDeaths
        kills
        opponentMatchupStatistics {
          championId
          totalMatches
          wins
        }
        duoMatchupStatistics {
          championId
          totalMatches
          wins
        }
      }
      playerInfo {
        ${PlayerInfo}
      }
      playerRank {
        exodiaUuid
        rankScores {
          losses
          lp
          promoProgress
          queueType
          rank
          role
          seasonId
          tier
          wins
          lastUpdatedAt
        }
      }
    }
  }
`;

export const UPDATE_EMAIL_ADDRESS = gql`
  mutation UpdateEmail($newEmail: String!, $oldEmail: String!, $password: String!) {
    updateEmail(newEmail: $newEmail, oldEmail: $oldEmail, password: $password) {
      token
    }
  }
`;

export const GET_MULTISEARCH = gql`
  query GetMultisearch($regionId: [String], $riotUserName: [String!], $riotTagLine: [String!]) {
    getMultisearch(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      roleStats {
        games
        roleName
        wins
      }
      winsLastFifteen
      totalGamesLastFifteen
      winperc
      winstreak
      riotUserName
      riotTagLine
      rankData {
        lastUpdatedAt
        losses
        lp
        promoProgress
        queueType
        rank
        role
        seasonId
        tier
        wins
      }
      worstChamps {
        champId
        games
        wins
        kills
        deaths
        assists
      }
      bestChamps {
        champId
        games
        wins
        kills
        deaths
        assists
      }
    }
  }
`;

export const UPDATE_PRIMARY_SUMMONER = gql`
  query UpdatePrimarySummoner($regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    updatePrimarySummoner(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      success
    }
  }
`;

export const VERIFY_RIOT_CODE = gql`
  query VerifyRiotCode {
    verifyRiotCode {
      success
    }
  }
`;

export const GET_RIOT_VERIFICATION_ICON = gql`
  query requestRiotVerificationCode {
    requestRiotVerificationCode {
      iconId
    }
  }
`;

export const UPSERT_MARVEL_RIVALS_CONTACT = gql`
  query upsertMarvelRivalsContact($email: String!) {
    upsertMarvelRivalsContact(email: $email) {
      success
    }
  }
`;
