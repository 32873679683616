import * as Icons from "@ugitgud/legos/ui/icon/raster/valorant";
import { ValorantIconName } from "@ugitgud/legos/ui/icon/raster/valorant/ValorantIconTypes";

export const getValorantIconURL = (name: ValorantIconName) => {
  switch(name){
    // insert generated code here
    case "weapons-tour-de-force": {
      return Icons.WeaponsTourDeForce;
    }
    case "weapons-headhunter": {
      return Icons.WeaponsHeadhunter;
    }
    case "premier-division-unknown": {
      return Icons.PremierDivisionUnknown;
    }
    case "premier-division-open": {
      return Icons.PremierDivisionOpen;
    }
    case "premier-division-invite": {
      return Icons.PremierDivisionInvite;
    }
    case "premier-division-intermediate": {
      return Icons.PremierDivisionIntermediate;
    }
    case "premier-division-elite": {
      return Icons.PremierDivisionElite;
    }
    case "premier-division-contender": {
      return Icons.PremierDivisionContender;
    }
    case "premier-division-advanced": {
      return Icons.PremierDivisionAdvanced;
    }
    case "premier-conference-us": {
      return Icons.PremierConferenceUs;
    }
    case "premier-conference-tr": {
      return Icons.PremierConferenceTr;
    }
    case "premier-conference-na": {
      return Icons.PremierConferenceNa;
    }
    case "premier-conference-me": {
      return Icons.PremierConferenceMe;
    }
    case "premier-conference-latam": {
      return Icons.PremierConferenceLatam;
    }
    case "premier-conference-kr": {
      return Icons.PremierConferenceKr;
    }
    case "premier-conference-jpn": {
      return Icons.PremierConferenceJpn;
    }
    case "premier-conference-ind": {
      return Icons.PremierConferenceInd;
    }
    case "premier-conference-eu": {
      return Icons.PremierConferenceEu;
    }
    case "premier-conference-br": {
      return Icons.PremierConferenceBr;
    }
    case "premier-conference-aus": {
      return Icons.PremierConferenceAus;
    }
    case "premier-conference-apac": {
      return Icons.PremierConferenceApac;
    }
    case "weapon-golden-gun": {
      return Icons.WeaponGoldenGun;
    }
    case "weapon-melee": {
      return Icons.WeaponMelee;
    }
    case "weapon-stinger": {
      return Icons.WeaponStinger;
    }
    case "weapon-spectre": {
      return Icons.WeaponSpectre;
    }
    case "weapon-marshal": {
      return Icons.WeaponMarshal;
    }
    case "weapon-outlaw": {
      return Icons.WeaponOutlaw;
    }
    case "weapon-guardian": {
      return Icons.WeaponGuardian;
    }
    case "weapon-operator": {
      return Icons.WeaponOperator;
    }
    case "weapon-shorty": {
      return Icons.WeaponShorty;
    }
    case "weapon-sheriff": {
      return Icons.WeaponSheriff;
    }
    case "weapon-ghost": {
      return Icons.WeaponGhost;
    }
    case "weapon-classic": {
      return Icons.WeaponClassic;
    }
    case "weapon-frenzy": {
      return Icons.WeaponFrenzy;
    }
    case "weapon-bucky": {
      return Icons.WeaponBucky;
    }
    case "weapon-judge": {
      return Icons.WeaponJudge;
    }
    case "weapon-phantom": {
      return Icons.WeaponPhantom;
    }
    case "weapon-bulldog": {
      return Icons.WeaponBulldog;
    }
    case "weapon-vandal": {
      return Icons.WeaponVandal;
    }
    case "weapon-ares": {
      return Icons.WeaponAres;
    }
    case "weapon-odin": {
      return Icons.WeaponOdin;
    }
    case "rank-27": {
      return Icons.Rank_27;
    }
    case "rank-ep-5-27": {
      return Icons.RankEp_5_27;
    }
    case "rank-26": {
      return Icons.Rank_26;
    }
    case "rank-ep-5-26": {
      return Icons.RankEp_5_26;
    }
    case "rank-25": {
      return Icons.Rank_25;
    }
    case "rank-ep-5-25": {
      return Icons.RankEp_5_25;
    }
    case "rank-24": {
      return Icons.Rank_24;
    }
    case "rank-ep-5-24": {
      return Icons.RankEp_5_24;
    }
    case "rank-23": {
      return Icons.Rank_23;
    }
    case "rank-ep-5-23": {
      return Icons.RankEp_5_23;
    }
    case "rank-22": {
      return Icons.Rank_22;
    }
    case "rank-ep-5-22": {
      return Icons.RankEp_5_22;
    }
    case "rank-21": {
      return Icons.Rank_21;
    }
    case "rank-ep-5-21": {
      return Icons.RankEp_5_21;
    }
    case "rank-20": {
      return Icons.Rank_20;
    }
    case "rank-ep-5-20": {
      return Icons.RankEp_5_20;
    }
    case "rank-19": {
      return Icons.Rank_19;
    }
    case "rank-ep-5-19": {
      return Icons.RankEp_5_19;
    }
    case "rank-18": {
      return Icons.Rank_18;
    }
    case "rank-ep-5-18": {
      return Icons.RankEp_5_18;
    }
    case "rank-17": {
      return Icons.Rank_17;
    }
    case "rank-ep-5-17": {
      return Icons.RankEp_5_17;
    }
    case "rank-16": {
      return Icons.Rank_16;
    }
    case "rank-ep-5-16": {
      return Icons.RankEp_5_16;
    }
    case "rank-15": {
      return Icons.Rank_15;
    }
    case "rank-ep-5-15": {
      return Icons.RankEp_5_15;
    }
    case "rank-14": {
      return Icons.Rank_14;
    }
    case "rank-ep-5-14": {
      return Icons.RankEp_5_14;
    }
    case "rank-13": {
      return Icons.Rank_13;
    }
    case "rank-ep-5-13": {
      return Icons.RankEp_5_13;
    }
    case "rank-12": {
      return Icons.Rank_12;
    }
    case "rank-ep-5-12": {
      return Icons.RankEp_5_12;
    }
    case "rank-11": {
      return Icons.Rank_11;
    }
    case "rank-ep-5-11": {
      return Icons.RankEp_5_11;
    }
    case "rank-10": {
      return Icons.Rank_10;
    }
    case "rank-ep-5-10": {
      return Icons.RankEp_5_10;
    }
    case "rank-9": {
      return Icons.Rank_9;
    }
    case "rank-ep-5-9": {
      return Icons.RankEp_5_9;
    }
    case "rank-8": {
      return Icons.Rank_8;
    }
    case "rank-ep-5-8": {
      return Icons.RankEp_5_8;
    }
    case "rank-7": {
      return Icons.Rank_7;
    }
    case "rank-ep-5-7": {
      return Icons.RankEp_5_7;
    }
    case "rank-6": {
      return Icons.Rank_6;
    }
    case "rank-ep-5-6": {
      return Icons.RankEp_5_6;
    }
    case "rank-5": {
      return Icons.Rank_5;
    }
    case "rank-ep-5-5": {
      return Icons.RankEp_5_5;
    }
    case "rank-4": {
      return Icons.Rank_4;
    }
    case "rank-ep-5-4": {
      return Icons.RankEp_5_4;
    }
    case "rank-3": {
      return Icons.Rank_3;
    }
    case "rank-ep-5-3": {
      return Icons.RankEp_5_3;
    }
    case "rank-0": {
      return Icons.Rank_0;
    }
    case "rank-ep-5-0": {
      return Icons.RankEp_5_0;
    }
    case "rank-ep-4-24": {
      return Icons.RankEp_4_24;
    }
    case "rank-ep-4-23": {
      return Icons.RankEp_4_23;
    }
    case "rank-ep-4-22": {
      return Icons.RankEp_4_22;
    }
    case "rank-ep-4-21": {
      return Icons.RankEp_4_21;
    }
    case "rank-ep-4-20": {
      return Icons.RankEp_4_20;
    }
    case "rank-ep-4-19": {
      return Icons.RankEp_4_19;
    }
    case "rank-ep-4-18": {
      return Icons.RankEp_4_18;
    }
    case "rank-ep-4-17": {
      return Icons.RankEp_4_17;
    }
    case "rank-ep-4-16": {
      return Icons.RankEp_4_16;
    }
    case "rank-ep-4-15": {
      return Icons.RankEp_4_15;
    }
    case "rank-ep-4-14": {
      return Icons.RankEp_4_14;
    }
    case "rank-ep-4-13": {
      return Icons.RankEp_4_13;
    }
    case "rank-ep-4-12": {
      return Icons.RankEp_4_12;
    }
    case "rank-ep-4-11": {
      return Icons.RankEp_4_11;
    }
    case "rank-ep-4-10": {
      return Icons.RankEp_4_10;
    }
    case "rank-ep-4-9": {
      return Icons.RankEp_4_9;
    }
    case "rank-ep-4-8": {
      return Icons.RankEp_4_8;
    }
    case "rank-ep-4-7": {
      return Icons.RankEp_4_7;
    }
    case "rank-ep-4-6": {
      return Icons.RankEp_4_6;
    }
    case "rank-ep-4-5": {
      return Icons.RankEp_4_5;
    }
    case "rank-ep-4-4": {
      return Icons.RankEp_4_4;
    }
    case "rank-ep-4-3": {
      return Icons.RankEp_4_3;
    }
    case "rank-ep-4-0": {
      return Icons.RankEp_4_0;
    }
    case "rank-ep-3-24": {
      return Icons.RankEp_3_24;
    }
    case "rank-ep-3-23": {
      return Icons.RankEp_3_23;
    }
    case "rank-ep-3-22": {
      return Icons.RankEp_3_22;
    }
    case "rank-ep-3-21": {
      return Icons.RankEp_3_21;
    }
    case "rank-ep-3-20": {
      return Icons.RankEp_3_20;
    }
    case "rank-ep-3-19": {
      return Icons.RankEp_3_19;
    }
    case "rank-ep-3-18": {
      return Icons.RankEp_3_18;
    }
    case "rank-ep-3-17": {
      return Icons.RankEp_3_17;
    }
    case "rank-ep-3-16": {
      return Icons.RankEp_3_16;
    }
    case "rank-ep-3-15": {
      return Icons.RankEp_3_15;
    }
    case "rank-ep-3-14": {
      return Icons.RankEp_3_14;
    }
    case "rank-ep-3-13": {
      return Icons.RankEp_3_13;
    }
    case "rank-ep-3-12": {
      return Icons.RankEp_3_12;
    }
    case "rank-ep-3-11": {
      return Icons.RankEp_3_11;
    }
    case "rank-ep-3-10": {
      return Icons.RankEp_3_10;
    }
    case "rank-ep-3-9": {
      return Icons.RankEp_3_9;
    }
    case "rank-ep-3-8": {
      return Icons.RankEp_3_8;
    }
    case "rank-ep-3-7": {
      return Icons.RankEp_3_7;
    }
    case "rank-ep-3-6": {
      return Icons.RankEp_3_6;
    }
    case "rank-ep-3-5": {
      return Icons.RankEp_3_5;
    }
    case "rank-ep-3-4": {
      return Icons.RankEp_3_4;
    }
    case "rank-ep-3-3": {
      return Icons.RankEp_3_3;
    }
    case "rank-ep-3-0": {
      return Icons.RankEp_3_0;
    }
    case "rank-ep-2-24": {
      return Icons.RankEp_2_24;
    }
    case "rank-ep-2-21": {
      return Icons.RankEp_2_21;
    }
    case "rank-ep-2-20": {
      return Icons.RankEp_2_20;
    }
    case "rank-ep-2-19": {
      return Icons.RankEp_2_19;
    }
    case "rank-ep-2-18": {
      return Icons.RankEp_2_18;
    }
    case "rank-ep-2-17": {
      return Icons.RankEp_2_17;
    }
    case "rank-ep-2-16": {
      return Icons.RankEp_2_16;
    }
    case "rank-ep-2-15": {
      return Icons.RankEp_2_15;
    }
    case "rank-ep-2-14": {
      return Icons.RankEp_2_14;
    }
    case "rank-ep-2-13": {
      return Icons.RankEp_2_13;
    }
    case "rank-ep-2-12": {
      return Icons.RankEp_2_12;
    }
    case "rank-ep-2-11": {
      return Icons.RankEp_2_11;
    }
    case "rank-ep-2-10": {
      return Icons.RankEp_2_10;
    }
    case "rank-ep-2-9": {
      return Icons.RankEp_2_9;
    }
    case "rank-ep-2-8": {
      return Icons.RankEp_2_8;
    }
    case "rank-ep-2-7": {
      return Icons.RankEp_2_7;
    }
    case "rank-ep-2-6": {
      return Icons.RankEp_2_6;
    }
    case "rank-ep-2-5": {
      return Icons.RankEp_2_5;
    }
    case "rank-ep-2-4": {
      return Icons.RankEp_2_4;
    }
    case "rank-ep-2-3": {
      return Icons.RankEp_2_3;
    }
    case "rank-ep-2-0": {
      return Icons.RankEp_2_0;
    }
    case "rank-ep-1-24": {
      return Icons.RankEp_1_24;
    }
    case "rank-ep-1-23": {
      return Icons.RankEp_1_23;
    }
    case "rank-ep-1-22": {
      return Icons.RankEp_1_22;
    }
    case "rank-ep-1-21": {
      return Icons.RankEp_1_21;
    }
    case "rank-ep-1-20": {
      return Icons.RankEp_1_20;
    }
    case "rank-ep-1-19": {
      return Icons.RankEp_1_19;
    }
    case "rank-ep-1-18": {
      return Icons.RankEp_1_18;
    }
    case "rank-ep-1-17": {
      return Icons.RankEp_1_17;
    }
    case "rank-ep-1-16": {
      return Icons.RankEp_1_16;
    }
    case "rank-ep-1-15": {
      return Icons.RankEp_1_15;
    }
    case "rank-ep-1-14": {
      return Icons.RankEp_1_14;
    }
    case "rank-ep-1-13": {
      return Icons.RankEp_1_13;
    }
    case "rank-ep-1-12": {
      return Icons.RankEp_1_12;
    }
    case "rank-ep-1-11": {
      return Icons.RankEp_1_11;
    }
    case "rank-ep-1-10": {
      return Icons.RankEp_1_10;
    }
    case "rank-ep-1-9": {
      return Icons.RankEp_1_9;
    }
    case "rank-ep-1-8": {
      return Icons.RankEp_1_8;
    }
    case "rank-ep-1-7": {
      return Icons.RankEp_1_7;
    }
    case "rank-ep-1-6": {
      return Icons.RankEp_1_6;
    }
    case "rank-ep-1-5": {
      return Icons.RankEp_1_5;
    }
    case "rank-ep-1-4": {
      return Icons.RankEp_1_4;
    }
    case "rank-ep-1-3": {
      return Icons.RankEp_1_3;
    }
    case "rank-ep-1-0": {
      return Icons.RankEp_1_0;
    }
    case "ability-jett-passive": {
      return Icons.AbilityJettPassive;
    }
    case "ability-jett-ult": {
      return Icons.AbilityJettUlt;
    }
    case "ability-jett-c": {
      return Icons.AbilityJettC;
    }
    case "ability-jett-e": {
      return Icons.AbilityJettE;
    }
    case "ability-jett-q": {
      return Icons.AbilityJettQ;
    }
    case "agent-jett": {
      return Icons.AgentJett;
    }
    case "ability-omen-ult": {
      return Icons.AbilityOmenUlt;
    }
    case "ability-omen-c": {
      return Icons.AbilityOmenC;
    }
    case "ability-omen-e": {
      return Icons.AbilityOmenE;
    }
    case "ability-omen-q": {
      return Icons.AbilityOmenQ;
    }
    case "agent-omen": {
      return Icons.AgentOmen;
    }
    case "ability-reyna-ult": {
      return Icons.AbilityReynaUlt;
    }
    case "ability-reyna-c": {
      return Icons.AbilityReynaC;
    }
    case "ability-reyna-e": {
      return Icons.AbilityReynaE;
    }
    case "ability-reyna-q": {
      return Icons.AbilityReynaQ;
    }
    case "agent-reyna": {
      return Icons.AgentReyna;
    }
    case "ability-sage-ult": {
      return Icons.AbilitySageUlt;
    }
    case "ability-sage-c": {
      return Icons.AbilitySageC;
    }
    case "ability-sage-e": {
      return Icons.AbilitySageE;
    }
    case "ability-sage-q": {
      return Icons.AbilitySageQ;
    }
    case "agent-sage": {
      return Icons.AgentSage;
    }
    case "ability-waylay-ult": {
      return Icons.AbilityWaylayUlt;
    }
    case "ability-waylay-q": {
      return Icons.AbilityWaylayQ;
    }
    case "ability-waylay-c": {
      return Icons.AbilityWaylayC;
    }
    case "ability-waylay-e": {
      return Icons.AbilityWaylayE;
    }
    case "agent-waylay": {
      return Icons.AgentWaylay;
    }
    case "ability-yoru-ult": {
      return Icons.AbilityYoruUlt;
    }
    case "ability-yoru-e": {
      return Icons.AbilityYoruE;
    }
    case "ability-yoru-q": {
      return Icons.AbilityYoruQ;
    }
    case "ability-yoru-c": {
      return Icons.AbilityYoruC;
    }
    case "agent-yoru": {
      return Icons.AgentYoru;
    }
    case "ability-neon-ult": {
      return Icons.AbilityNeonUlt;
    }
    case "ability-neon-c": {
      return Icons.AbilityNeonC;
    }
    case "ability-neon-q": {
      return Icons.AbilityNeonQ;
    }
    case "ability-neon-e": {
      return Icons.AbilityNeonE;
    }
    case "agent-neon": {
      return Icons.AgentNeon;
    }
    case "ability-clove-q": {
      return Icons.AbilityCloveQ;
    }
    case "ability-clove-ult": {
      return Icons.AbilityCloveUlt;
    }
    case "ability-clove-e": {
      return Icons.AbilityCloveE;
    }
    case "ability-clove-c": {
      return Icons.AbilityCloveC;
    }
    case "agent-clove": {
      return Icons.AgentClove;
    }
    case "ability-iso-c": {
      return Icons.AbilityIsoC;
    }
    case "ability-iso-e": {
      return Icons.AbilityIsoE;
    }
    case "ability-iso-ult": {
      return Icons.AbilityIsoUlt;
    }
    case "ability-iso-q": {
      return Icons.AbilityIsoQ;
    }
    case "agent-iso": {
      return Icons.AgentIso;
    }
    case "ability-brimstone-ult": {
      return Icons.AbilityBrimstoneUlt;
    }
    case "ability-brimstone-e": {
      return Icons.AbilityBrimstoneE;
    }
    case "ability-brimstone-q": {
      return Icons.AbilityBrimstoneQ;
    }
    case "ability-brimstone-c": {
      return Icons.AbilityBrimstoneC;
    }
    case "agent-brimstone": {
      return Icons.AgentBrimstone;
    }
    case "ability-astra-passive": {
      return Icons.AbilityAstraPassive;
    }
    case "ability-astra-ult": {
      return Icons.AbilityAstraUlt;
    }
    case "ability-astra-c": {
      return Icons.AbilityAstraC;
    }
    case "ability-astra-e": {
      return Icons.AbilityAstraE;
    }
    case "ability-astra-q": {
      return Icons.AbilityAstraQ;
    }
    case "agent-astra": {
      return Icons.AgentAstra;
    }
    case "ability-phoenix-ult": {
      return Icons.AbilityPhoenixUlt;
    }
    case "ability-phoenix-e": {
      return Icons.AbilityPhoenixE;
    }
    case "ability-phoenix-q": {
      return Icons.AbilityPhoenixQ;
    }
    case "ability-phoenix-c": {
      return Icons.AbilityPhoenixC;
    }
    case "agent-phoenix": {
      return Icons.AgentPhoenix;
    }
    case "ability-viper-ult": {
      return Icons.AbilityViperUlt;
    }
    case "ability-viper-c": {
      return Icons.AbilityViperC;
    }
    case "ability-viper-e": {
      return Icons.AbilityViperE;
    }
    case "ability-viper-q": {
      return Icons.AbilityViperQ;
    }
    case "agent-viper": {
      return Icons.AgentViper;
    }
    case "ability-vyse-ult": {
      return Icons.AbilityVyseUlt;
    }
    case "ability-vyse-c": {
      return Icons.AbilityVyseC;
    }
    case "ability-vyse-e": {
      return Icons.AbilityVyseE;
    }
    case "ability-vyse-q": {
      return Icons.AbilityVyseQ;
    }
    case "agent-vyse": {
      return Icons.AgentVyse;
    }
    case "ability-harbor-ult": {
      return Icons.AbilityHarborUlt;
    }
    case "ability-harbor-e": {
      return Icons.AbilityHarborE;
    }
    case "ability-harbor-c": {
      return Icons.AbilityHarborC;
    }
    case "ability-harbor-q": {
      return Icons.AbilityHarborQ;
    }
    case "agent-harbor": {
      return Icons.AgentHarbor;
    }
    case "ability-killjoy-ult": {
      return Icons.AbilityKilljoyUlt;
    }
    case "ability-killjoy-e": {
      return Icons.AbilityKilljoyE;
    }
    case "ability-killjoy-q": {
      return Icons.AbilityKilljoyQ;
    }
    case "ability-killjoy-c": {
      return Icons.AbilityKilljoyC;
    }
    case "agent-killjoy": {
      return Icons.AgentKilljoy;
    }
    case "ability-sova-ult": {
      return Icons.AbilitySovaUlt;
    }
    case "ability-sova-c": {
      return Icons.AbilitySovaC;
    }
    case "ability-sova-e": {
      return Icons.AbilitySovaE;
    }
    case "ability-sova-q": {
      return Icons.AbilitySovaQ;
    }
    case "agent-sova": {
      return Icons.AgentSova;
    }
    case "ability-cypher-ult": {
      return Icons.AbilityCypherUlt;
    }
    case "ability-cypher-c": {
      return Icons.AbilityCypherC;
    }
    case "ability-cypher-e": {
      return Icons.AbilityCypherE;
    }
    case "ability-cypher-q": {
      return Icons.AbilityCypherQ;
    }
    case "agent-cypher": {
      return Icons.AgentCypher;
    }
    case "ability-skye-ult": {
      return Icons.AbilitySkyeUlt;
    }
    case "ability-skye-c": {
      return Icons.AbilitySkyeC;
    }
    case "ability-skye-e": {
      return Icons.AbilitySkyeE;
    }
    case "ability-skye-q": {
      return Icons.AbilitySkyeQ;
    }
    case "agent-skye": {
      return Icons.AgentSkye;
    }
    case "ability-kayo-ult": {
      return Icons.AbilityKayoUlt;
    }
    case "ability-kayo-e": {
      return Icons.AbilityKayoE;
    }
    case "ability-kayo-q": {
      return Icons.AbilityKayoQ;
    }
    case "ability-kayo-c": {
      return Icons.AbilityKayoC;
    }
    case "agent-kayo": {
      return Icons.AgentKayo;
    }
    case "ability-chamber-ult": {
      return Icons.AbilityChamberUlt;
    }
    case "ability-chamber-q": {
      return Icons.AbilityChamberQ;
    }
    case "ability-chamber-c": {
      return Icons.AbilityChamberC;
    }
    case "ability-chamber-e": {
      return Icons.AbilityChamberE;
    }
    case "agent-chamber": {
      return Icons.AgentChamber;
    }
    case "ability-raze-ult": {
      return Icons.AbilityRazeUlt;
    }
    case "ability-raze-c": {
      return Icons.AbilityRazeC;
    }
    case "ability-raze-e": {
      return Icons.AbilityRazeE;
    }
    case "ability-raze-q": {
      return Icons.AbilityRazeQ;
    }
    case "agent-raze": {
      return Icons.AgentRaze;
    }
    case "ability-tejo-c": {
      return Icons.AbilityTejoC;
    }
    case "ability-tejo-ult": {
      return Icons.AbilityTejoUlt;
    }
    case "ability-tejo-q": {
      return Icons.AbilityTejoQ;
    }
    case "ability-tejo-e": {
      return Icons.AbilityTejoE;
    }
    case "agent-tejo": {
      return Icons.AgentTejo;
    }
    case "ability-deadlock-ult": {
      return Icons.AbilityDeadlockUlt;
    }
    case "ability-deadlock-e": {
      return Icons.AbilityDeadlockE;
    }
    case "ability-deadlock-c": {
      return Icons.AbilityDeadlockC;
    }
    case "ability-deadlock-q": {
      return Icons.AbilityDeadlockQ;
    }
    case "agent-deadlock": {
      return Icons.AgentDeadlock;
    }
    case "ability-breach-ult": {
      return Icons.AbilityBreachUlt;
    }
    case "ability-breach-c": {
      return Icons.AbilityBreachC;
    }
    case "ability-breach-e": {
      return Icons.AbilityBreachE;
    }
    case "ability-breach-q": {
      return Icons.AbilityBreachQ;
    }
    case "agent-breach": {
      return Icons.AgentBreach;
    }
    case "ability-fade-ult": {
      return Icons.AbilityFadeUlt;
    }
    case "ability-fade-c": {
      return Icons.AbilityFadeC;
    }
    case "ability-fade-e": {
      return Icons.AbilityFadeE;
    }
    case "ability-fade-q": {
      return Icons.AbilityFadeQ;
    }
    case "agent-fade": {
      return Icons.AgentFade;
    }
    case "ability-gekko-ult": {
      return Icons.AbilityGekkoUlt;
    }
    case "ability-gekko-c": {
      return Icons.AbilityGekkoC;
    }
    case "ability-gekko-e": {
      return Icons.AbilityGekkoE;
    }
    case "ability-gekko-q": {
      return Icons.AbilityGekkoQ;
    }
    case "agent-gekko": {
      return Icons.AgentGekko;
    }
    default:
      return null;
    }
}