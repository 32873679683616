import React, { useState } from "react";
import SummonerFinder from "components/SummonerFinder";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { useLazyQuery } from "@apollo/client";
import { UPDATE_PRIMARY_SUMMONER, GET_RIOT_VERIFICATION_ICON } from "@ugg/shared/api/graphql/queries";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";

const VerificationRiotIdPage = (props) => {
  const { incStep, regionId, riotId } = props;
  const [inputText, setInputText] = useState(riotId);
  const [region, setRegion] = useState(regionId);
  const [errorMsg, setErrorMsg] = useState("");
  const [inputRiotUserName, inputRiotTagLine] = splitRiotId(inputText);

  const [getVerificationIcon, { loading: codeLoading }] = useLazyQuery(GET_RIOT_VERIFICATION_ICON, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      incStep(data.requestRiotVerificationCode.iconId);
    },
    onError: (error) => {
      console.error(error);
      setErrorMsg("Could not find Riot ID. Please try again.");
    },
  });

  const [updateSummoner, { loading: updateLoading }] = useLazyQuery(UPDATE_PRIMARY_SUMMONER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getVerificationIcon();
    },
    onError: (error) => {
      console.error(error);
      setErrorMsg("Could not update summoner. Please try again.");
    },
  });

  const verifyForm = () => {
    if (inputRiotUserName && inputRiotTagLine) {
      updateSummoner({
        variables: {
          regionId: region,
          riotUserName: inputRiotUserName,
          riotTagLine: inputRiotTagLine,
        },
      });
    } else {
      setErrorMsg("Invalid Riot ID");
    }
  };

  return (
    <div className="summoner-name">
      <div className="small-header">Verify Your Account</div>
      <div className="step-text">Step 1 of 2</div>
      <div className="basic-text">Confirm your Riot ID</div>
      {errorMsg && <div className="mb-[3px] text-[14px] text-accent-orange-500">{errorMsg}</div>}
      <SummonerFinder
        className="mb-[24px]"
        placeholder="Riot ID"
        riotId={inputText}
        summonerRegion={region}
        onSummonerChange={(e) => {
          setErrorMsg("");
          setInputText(e.target.value);
        }}
        onRegionChange={(region) => setRegion(region)}
        tabIndex={1000}
        error={errorMsg}
        autoFocus
      />
      <ButtonSuccess
        className="btn-light-blue"
        label="Verify"
        isLoading={updateLoading || codeLoading}
        onClick={verifyForm}
        bForceSubmitOnEnter={true}
      />
    </div>
  );
};

export default VerificationRiotIdPage;
