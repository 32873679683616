import { window } from "global";
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useResponsive } from "./ResponsiveContext";

const checkWindowWidth = ({ min, max, at, breakpoints }) => {
  let shouldRender = false;
  if (window) {
    const dimtoCheck = window.innerWidth;

    let minBreakpoint = undefined;
    let maxBreakpoint = undefined;
    for (const breakpoint of Object.entries(breakpoints)) {
      minBreakpoint = minBreakpoint === undefined || minBreakpoint[1][0] > breakpoint[1][0] ? breakpoint : minBreakpoint;
      maxBreakpoint = maxBreakpoint === undefined || maxBreakpoint[1][1] < breakpoint[1][1] ? breakpoint : maxBreakpoint;
    }

    if ((min && max) || at) {
      if (max === maxBreakpoint[0]) {
        shouldRender = dimtoCheck >= breakpoints[min || at][0];
      } else if (min === minBreakpoint[0]) {
        shouldRender = dimtoCheck <= breakpoints[max || at][1];
      } else {
        shouldRender = dimtoCheck >= breakpoints[min || at][0] && dimtoCheck < breakpoints[max || at][1];
      }
    }
  }
  return shouldRender;
};

export const MediaQuery = (props) => {
  const { at, min, max, renderNullOnFail, isClient, children } = props;
  const [clientInit, setClientInit] = useState(false);
  const [render, setRender] = useState(false);
  const { breakpoints } = useResponsive();

  useEffect(() => {
    // server will not reach this step
    if (isClient) {
      setClientInit(true);
    }
  }, []);

  useEffect(() => {
    if (window && renderNullOnFail) {
      const checkIfShouldRender = () => {
        const shouldRender = checkWindowWidth({ min, max, at, breakpoints });
        setRender(shouldRender);
      };

      checkIfShouldRender();
      window.addEventListener("resize", checkIfShouldRender);
      return () => window.removeEventListener("resize", checkIfShouldRender);
    }
  }, [renderNullOnFail]);

  if (renderNullOnFail && !render) return null;
  if (isClient && !clientInit) return null;

  if (at && (min || max)) {
    throw new Error("Choose a single breakpoint or a range of breakpoints");
  }
  const buildClassName = () => {
    const prefixClassName = "media-query";
    let breakPointClassName = "";
    if (at) {
      breakPointClassName = at;
    } else {
      if (min === max) {
        breakPointClassName = min;
      } else {
        const sortedBreakpoints = [min, max];
        breakPointClassName = `${sortedBreakpoints[0]}__${sortedBreakpoints[1]}`;
      }
    }
    return `${prefixClassName}_${breakPointClassName}`;
  };
  const baseClassNames = classnames("media-query", buildClassName());

  // console.log(children)
  if (typeof children === "function") {
    return children(baseClassNames);
  }
  // React.Children.map(children, child => {
  //   console.log(child);
  // })
  if (!children) return null;

  return React.Children.map(
    children,
    (child) =>
      child &&
      React.cloneElement(child, {
        className: classnames(child.props.className, baseClassNames),
      }),
  );
};
