const CULL_PASSIVE = "Attacks restore 3 Health per hit.";

export const parseDescription = (description: string) => {
  const mainRegex = /<mainText>|<\/mainText>|<stats>|<attention>|<\/attention>|<ornnBonus>|<\/ornnBonus>|<healing>|<\/healing>/gi;
  const parsed = description.replace(mainRegex, "");

  // spliting stats and the rest of the description
  const statSplit = parsed.split("</stats>");
  const stats = statSplit[0].split("<br>");
  const allStats = [...stats];

  // splitting the description and the rules at the end
  const rulesSplit = statSplit[1]?.split("<rules>");
  const rules = rulesSplit?.[1];

  const flavorSplit = rulesSplit?.[0]?.split("<flavorText>");
  const flavorText = flavorSplit?.[1];

  // splitting the description and the mythic part
  const mythicSplit = flavorSplit?.[0]?.split("<rarityMythic>");

  const mythicRegex = /<\/rarityMythic>|<rarityLegendary>|<\/rarityLegendary>|/gi;
  const mythicPassive = mythicSplit?.[1]?.replace(mythicRegex, "");

  const mainDesc = mythicSplit?.[0];
  let actives: string[] = [];
  let passives: string[] = [];
  // if item has a PASSIVE & ACTIVE
  if (mainDesc?.includes("<passive") && mainDesc?.includes("<active>")) {
    const activeSplit = mainDesc.split("<passive>", 1);
    actives = cleanActiveDesc(activeSplit);

    const passiveSplit = mainDesc.split("<passive>");
    passiveSplit.shift();
    passives = cleanPassiveDesc(passiveSplit);
  } else if (mainDesc?.includes("<passive>")) {
    // passives = mainDesc.split("<passive>");

    passives = mainDesc.split("<li>");
    passives.shift();
    passives = cleanPassiveDesc(passives);

    // case where the passive has a stat
    passives.forEach((passive, i) => {
      if (passive.includes("Flight:")) {
        allStats.push("5% Move Speed");
      }
      if (passive.includes("Gouge:")) {
        allStats.push("10 Lethality");
      }
      if (passive.includes("Warmonger:")) {
        allStats.push("2.5% Omnivamp");
      }
      if (passive === CULL_PASSIVE) {
        // cull passive messes up item description
        passives.splice(i, 1);
      }
    });
  } else if (mainDesc?.includes("<active>")) {
    actives = cleanActiveDesc([mainDesc]);
  }

  return { stats, allStats, mythicPassive, actives, passives };
};

const cleanPassiveDesc = (passives: string[]) => {
  const regex = /<\/passive>|<passive>|<li>|<br>/gi;
  const parsedPassives = passives.map((passive) => {
    return passive.replace(regex, "");
  });
  return parsedPassives;
};

const cleanActiveDesc = (actives: string[]) => {
  const regex = /<active>|<\/active>|<li>|<br>/gi;
  const parsedActives = actives.map((active) => {
    return active.replace(regex, "");
  });
  return parsedActives;
};

export const isMythicItem = (description: string) => {
  if (description?.includes("<rarityMythic>")) {
    return true;
  }
  return false;
};
