export const costEfficiencyMap: Record<string, number> = {
  Health: 2.667, // 1028
  Mana: 1.4, // 1027
  "Attack Damage": 35, // 1036
  "Ability Power": 21.75, // 1052
  "Magic Resist": 18, // 1033
  "Attack Speed": 25, // 1042
  "Movement Speed": 12, // 1001
  "Move Speed": 12, // 1001
  "%Move Speed": 80, // 3066
  "Ability Haste": 26.667, // 3067
  "Critical Strike Chance": 40, // 1018
  "Armor Penetration": 41.667,
  "%Armor Penetration": 41.667, // 3035
  "Magic Penetration": 31.111, // 3020
  "%Magic Penetration": 54.327, // 4630
  Lethality: 56.6, // ? 3134 ?
  "Life Steal": 53.571, // 1053
  Omnivamp: 39.65, // 4635
  "Gold Per 10 Seconds": 0,
  "Base Health Regen": 3, // 1006
  "Base Mana Regen": 5, // 1004
  "Heal and Shield Power": 55, // 3114
  Armor: 20, // 1029
};
