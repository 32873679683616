import { window, document } from "global";
import React from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { loadableReady } from "@loadable/component";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import globalReactN, { setClientReactNState } from "lib/reactn-init";
import { createI18nextInstance } from "lib/i18next-init";
import { initJSONFetcher } from "@outplayed/json-fetcher";
import AppCrashErrorHandler from "components/AppCrash/AppCrashErrorHandler";
import ApolloContainer from "components/ApolloContainer";
import "lib/local-moment";
import { datadogLogs } from "@datadog/browser-logs";
import { UGGLegacyRouteAdapter } from "@ugitgud/legos/context/routing";
import { UggGAEventProvider } from "@ugitgud/legos/components/util/UggGAEventProvider";
import { initHighcharts } from "@ugg/shared/utils/highcharts/highcharts-init";
initHighcharts();

import { getSetAnonId } from "lib/analytics-helpers";
getSetAnonId();

// styling
import "stylesheets/app.scss";

import moment from "moment";
import ReactGA from "react-ga4";

moment.locale("en");

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactGA.initialize(process.env.UGG_PUBLIC_REACT_APP_GA_ID);

// Initialize reactn global state
const preloadedReactNState = Object.assign({}, window.__REACTN_PRELOADED_STATE__, window.__SSR_DATA__);
const ReactNProvider = globalReactN;
ReactNProvider.setGlobal(preloadedReactNState);
setClientReactNState(ReactNProvider);
delete window.__REACTN_PRELOADED_STATE__;
delete window.__SSR_DATA__;

initJSONFetcher(ReactNProvider);

// Add <meta charset="UTF-8"> to the top of header
// for Lighthouse best practices to work
if (document) {
  var head = document.getElementsByTagName("head")[0];
  var meta = document.createElement("meta");
  meta.setAttribute("charset", "UTF-8");
  head.insertBefore(meta, head.firstChild);
}

if (process.env.UGG_PUBLIC_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.UGG_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "ugg_lol_site",
    forwardErrorsToLogs: false,
    sampleRate: 100,
    beforeSend: (log) => {
      console.log(log);
    },
  });
}

// Browser Auto Refresh
if (process.env.NODE_ENV === "development") {
  const ws = new WebSocket("ws://localhost:8080?client=web");

  ws.onerror = () => {
    console.log("browser-reload ws not running");
  };

  ws.onopen = () => {
    console.log("connection");
  };
  ws.onmessage = (e) => {
    if (e.data === "reload") {
      window.location.reload();
    }
  };
}

const startHydrate = async () => {
  const curLang = ReactNProvider.getGlobal().language;
  const i18nextInstance = await createI18nextInstance(curLang);

  loadableReady(() => {
    hydrateRoot(
      document.getElementById("root"),
      <HelmetProvider>
        <I18nextProvider i18n={i18nextInstance}>
          <ReactNProvider>
            <BrowserRouter>
              <ApolloContainer>
                <AppCrashErrorHandler>
                  <UGGLegacyRouteAdapter>
                    <UggGAEventProvider defaultGame="lol" />
                    <App />
                  </UGGLegacyRouteAdapter>
                </AppCrashErrorHandler>
              </ApolloContainer>
            </BrowserRouter>
          </ReactNProvider>
        </I18nextProvider>
      </HelmetProvider>,
    );
  });
};

if (window) {
  window.onload = () => {
    setTimeout(() => {
      if (document) {
        const scripts = ["https://www.paypalobjects.com/api/checkout.js"];
        for (let i = 0; i < scripts.length; i++) {
          let script = document.createElement("script");
          script.src = scripts[i];
          script.async = true;
          document.getElementsByTagName("body")[0].appendChild(script);
        }
      }
    }, 1);
  };
}

window && sessionStorage.setItem("initial_page", window.location.pathname);
startHydrate();
