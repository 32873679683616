import TooltipContainerImport from "./src/TooltipContainer";
import CostAnalysisTooltipImport from "./src/tooltipInfoTypes/CostAnalysisTooltip";
export * from "./src/tooltipImages";
export * from "./src/TooltipZoomProvider";
export const TooltipContainer = TooltipContainerImport;
export const CostAnalysisTooltip = CostAnalysisTooltipImport;

export const TooltipOrientations = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};
