import { window } from "global";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useGlobal } from "reactn";
import { Helmet } from "react-helmet-async";
import { useMutation } from "@apollo/client";
import CustomInput from "components/CustomInput";
import PasswordChecker from "components/PasswordChecker";
import SummonerFinder from "components/SummonerFinder";
import ButtonSuccess from "components/Buttons/ButtonSuccess";

import { parseQueryString } from "@ugg/shared/utils/url-query-params";
import { addCookie } from "@ugg/shared/utils/cookies";
import { isValidEmail, isValidPassword } from "lib/form-helpers";
import { CREATE_ACCOUNT } from "@ugg/shared/api/graphql/queries";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

export const Signup = (props) => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search.substring(1);
  const parsedQueryString = parseQueryString(search);
  const [clientRegion] = useGlobal("clientRegion");

  const [showPasswordChecker, setPasswordChecker] = useState(false);
  const [signupError, setSignupError] = useState("");

  // Form
  const [form, setForm] = useState({
    email: { value: "", error: "" },
    password: { value: "", error: "" },
    riotId: { value: "", error: "" },
    summonerRegion: { value: clientRegion || "", error: "" },
    marketingOptOut: false,
  });

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const { email, password, riotId, summonerRegion, marketingOptOut } = form;

  const onCompleted = (data) => {
    const token = data.register.token;
    if (window) {
      addCookie("authToken", token);
      // localStorage.setItem("authToken", token);
      const signupVar = parsedQueryString.continue ? parsedQueryString.continue : "";
      const splitURL = signupVar.split("/");
      if (parsedQueryString.redirect_url) {
        window.location.href = `${parsedQueryString.redirect_url}`;
      } else if (splitURL.length === 1) {
        window.location.href = "/?onboarding=true";
      } else {
        window.location.href = `${AppRoutes.PREMIUM_CHECKOUT}?planType=${splitURL.pop()}`;
      }
    }
  };

  const onError = (apolloError) => {
    const error = JSON.parse(JSON.stringify(apolloError));
    const { graphQLErrors } = error;

    if (graphQLErrors[0].message === "duplicate_user") {
      setSignupError("An account with this E-mail already exists.");
    }
    // Other errors
    else {
      console.error(graphQLErrors);
      setSignupError("An unexpected error occurred. Please try again.");
    }
  };

  const [inputRiotUserName, inputRiotTagLine] = splitRiotId(riotId.value);
  const [createAccount, { loading: creatingAccount, data: createdAccount, error: errorCreateAccount }] = useMutation(
    CREATE_ACCOUNT,
    {
      onCompleted,
      onError,
      variables: {
        email: email.value,
        marketingOptOut: marketingOptOut,
        password: password.value,
        riotUserName: inputRiotUserName,
        riotTagLine: inputRiotTagLine,
        summonerRegion: summonerRegion.value,
      },
      context: { clientName: "auth-api" },
    },
  );

  const onInputChange = (key, value) => {
    setForm({ ...form, [key]: { ...form[key], ...value } });
  };

  const onPasswordFocus = () => {
    setPasswordChecker(true);
  };

  const onPasswordBlur = () => {
    setTimeout(() => {
      setPasswordChecker(false);
    }, 100);
  };

  const verifyForm = () => {
    let verifiedForm = {};
    let hasError = false;
    function mergeForm(state, error) {
      if (error) {
        hasError = true;
      }
      return Object.assign(verifiedForm, state);
    }

    // Email
    if (email.value.trim().length === 0) {
      verifiedForm = mergeForm({ email: { ...email, error: "Email address is required" } }, true);
    } else if (!isValidEmail(email.value.trim())) {
      verifiedForm = mergeForm({ email: { ...email, error: "Invalid email address" } }, true);
    } else {
      verifiedForm = mergeForm({ email: { ...email, error: "" } });
    }

    // Password
    if (!isValidPassword(password.value)) {
      verifiedForm = mergeForm(
        { password: { ...password, error: "Password must be at least 8 characters, with at least one letter and one number" } },
        true,
      );
    } else {
      verifiedForm = mergeForm({ password: { ...password, error: "" } });
    }

    // Summoner Name
    if (!inputRiotUserName || !inputRiotTagLine) {
      verifiedForm = mergeForm({ riotId: { ...riotId, error: "Riot ID is required" } }, true);
    } else {
      verifiedForm = mergeForm({ riotId: { ...riotId, error: "" } });
    }

    const newForm = { ...form, ...verifiedForm };
    return { form: newForm, error: hasError };
  };

  const completeSignup = (e) => {
    e.persist();
    const verifiedForm = verifyForm();
    setForm({ ...form, ...verifiedForm.form });

    if (verifiedForm.error) return;

    setSignupError("");
    createAccount();
  };

  return (
    <div className="content-side-padding w-full mt-[100px]">
      <Helmet>
        <title>U.GG - Signup</title>
      </Helmet>
      <div className="w-full max-w-[500px] mx-auto">
        <div className="flex mb-[8px] text-[14px] text-lavender-50">
          <Link to={AppRoutes.HOME}>&lt; Back to Home</Link>
        </div>
        <div className="w-full rounded-[6px] p-[32px] max-xs:p-[24px] bg-purple-200">
          <form onSubmit={(e) => e.preventDefault()} className="w-full">
            <Link className="flex mb-[24px]" to={AppRoutes.HOME}>
              <img className="w-[200px]" src="https://static.bigbrain.gg/assets/ugg/logo/ugg-logo.svg" />
            </Link>
            <div className="mb-[12px] text-white text-[30px] max-xs:text-[24px] font-medium font-barlow">Create an account</div>
            <div className="mb-[24px] text-lavender-50 text-[16px] font-inter">
              Already have an account?{" "}
              <Link className="underline text-accent-blue-400" to={{ pathname: AppRoutes.LOGIN, search }}>
                <span>Log In.</span>
              </Link>
            </div>
            {signupError && (
              <div className="flex items-center justify-center w-full h-[42px] mb-[24px] rounded-[3px] text-white font-bold text-[14px] bg-[orange]">
                {signupError}
              </div>
            )}
            <CustomInput
              className="
                mb-[24px] [&_input]:h-[42px]
                [&_input]:text-[16px] [&_input]:text-white [&_input]:rounded-[3px] [&_input]:border-[1px] [&_input]:border-[#31334a]
                [&_input]:bg-purple-100 [&_input:focus]:outline-none [&_input:focus]:border-[#919496]
                [&_input]:placeholder:text-lavender-50 [&_input]:placeholder:font-normal
                [&_.input-label]:text-[14px] [&_.input-label]:mb-[10px] [&_.input-label]:text-lavender-50 [&_.input-label]:font-inter
              "
              value={email.value}
              error={email.error}
              onChange={(e) => onInputChange("email", { value: e.target.value })}
              placeholder="Email"
              type="email"
              tabIndex={1000}
              autoFocus
              label={"Email"}
            />
            <CustomInput
              className="
                [&_input]:h-[42px]
                [&_input]:text-[16px] [&_input]:text-white [&_input]:rounded-[3px] [&_input]:border-[1px] [&_input]:border-[#31334a]
                [&_input]:bg-purple-100 [&_input:focus]:outline-none [&_input:focus]:border-[#919496]
                [&_input]:placeholder:text-lavender-50 [&_input]:placeholder:font-normal
                [&_.input-label]:text-[14px] [&_.input-label]:mb-[10px] [&_.input-label]:text-lavender-50 [&_.input-label]:font-inter
              "
              value={password.value}
              error={password.error}
              onChange={(e) => onInputChange("password", { value: e.target.value })}
              onFocus={onPasswordFocus}
              onBlur={onPasswordBlur}
              placeholder="Password"
              tabIndex={1001}
              type="password"
              label={"Password"}
              maxLength={128}
            />
            {showPasswordChecker && (
              <div className="mt-[12px]">
                <PasswordChecker password={password.value} />
              </div>
            )}
            <div className="w-full my-[24px]">
              {riotId.error && <div className="mb-[3px] text-accent-orange-500 text-[14px] font-medium">{riotId.error}</div>}
              <SummonerFinder
                className="
                  [&_input]:h-[42px]
                  [&_input]:text-[16px] [&_input]:text-white [&_input]:rounded-[3px] [&_input]:border-[1px] [&_input]:border-[#31334a]
                  [&_input]:bg-purple-100 [&_input:focus]:outline-none [&_input:focus]:border-[#919496]
                  [&_input]:placeholder:text-lavender-50 [&_input]:placeholder:font-normal
                  [&_.input-label]:text-[14px] [&_.input-label]:mb-[10px] [&_.input-label]:text-lavender-50 [&_.input-label]:font-inter
                "
                placeholder={"Summoner Name"}
                riotId={riotId.value}
                summonerRegion={summonerRegion.value}
                onSummonerChange={(e) => onInputChange("riotId", { value: e.target.value })}
                onRegionChange={(region) => onInputChange("summonerRegion", { value: region || "" })}
                tabIndex={1002}
                error={riotId.error}
              />
            </div>
            <div className="text-[12px] text-lavender-50 mb-[24px]">
              By clicking on Create Account, you agree to our{" "}
              <Link className="text-white underline" to={AppRoutes.TOS}>
                {"Terms of Service"}
              </Link>
              .
              <br />
              <br />
              To learn more about how U.GG collects, uses, shares and protects your personal data please read our{" "}
              <Link className="text-white underline" to={AppRoutes.PRIVACY_POLICY}>
                {"Privacy Policy"}
              </Link>
              .
            </div>
            <ButtonSuccess
              className="btn-blue h-[42px] !text-[14px]"
              label="Create Account"
              isLoading={creatingAccount}
              onClick={completeSignup}
              bForceSubmitOnEnter={true}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
