import * as React from "react";
import type { SVGProps } from "react";
export const SvgGameMarvelRivalsGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.48888 4.24695H2.02686L10.1313 8.30083L4.00355 8.20074L8.52641 13.1712H15.6616L8.84259 9.55203H14.3812L9.48888 4.24695Z"
      fill="#5C5C8E"
    />
    <path
      d="M17.46 5.35449L13.1888 7.07797L15.5867 6.85317L14.7624 10.1503H12.4395L15.9613 11.4991L17.46 5.35449Z"
      fill="#5C5C8E"
    />
    <path
      d="M0.45013 12.0986L4.72136 10.3751L2.32348 10.5999L3.14775 7.3028H5.4707L1.94881 5.95399L0.45013 12.0986Z"
      fill="#5C5C8E"
    />
  </svg>
);
