import React from "react";
import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import classnames from "classnames";
import Portal from "components/Portal";

import { ReactComponent as CloseIcon } from "svg/x.svg";
import { ReactComponent as DownIndicator } from "svg/triangle-arrow-down.svg";

const SEND_USER_FEEDBACK = gql`
  mutation ($content: String!) {
    userFeedbackWebsite(content: $content) {
      success
    }
  }
`;

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const formatEmailBody = (topic, name, email, message) => {
  return `Category: ${topic} 
    Name: ${name} 
    Email: ${email} 
    Message: ${message}`;
};

const options = [
  { value: "bug-report", label: "Bug Report" },
  { value: "feature-request", label: "Feature Request" },
  { value: "other", label: "Other" },
];

// Store the custom styles (outside of the .scss) for the drop down and arrow indications in the Form as they use react-selects lib.
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#25254B !important",
    color: state.isSelected ? "#CDDCFE" : "#7687AD",
    padding: "16px 12px",
  }),

  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#25254B",
    top: "42px",
  }),

  input: (provided, state) => ({
    ...provided,
    color: "#CDDCFE",
  }),

  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#25254B",
    border: "none !important",
    boxShadow: "none !imporant",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    color: "#7687AD",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: "#CDDCFE",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    marginRight: "8px",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownIndicator width={8} />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = (props) => {
  return <components.IndicatorSeparator {...props}></components.IndicatorSeparator>;
};

const FeedbackForm = ({ toggleFeedbackPopup, ...props }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState(null);
  const [message, setMessage] = useState("");
  const [buttonMessage, setButtonMessage] = useState("Submit");
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validTopic, setValidTopic] = useState(true);
  const [validMessage, setValidMessage] = useState(true);

  const [mutationFunction, {}] = useMutation(SEND_USER_FEEDBACK);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  return (
    <Portal>
      <div className="modal-container">
        <div className="modal-backdrop" />
        <div className="modal-window modal_settings">
          <div className="header_feedback">
            <div className="header_content_feedback">
              <div>Contact Us</div>
              <CloseIcon className="close-icon" onClick={toggleFeedbackPopup} />
            </div>
          </div>
          <div className="nav"></div>
          <div className="contact_body">
            <div className="contact-row">
              <div className="contact_form-group">
                <label>
                  Name
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      !validName && setValidName(true);
                    }}
                    className={classnames({ invalid: !validName })}
                    maxLength={254}
                  />
                </label>
                <label>
                  Email
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      !validEmail && setValidEmail(true);
                    }}
                    className={classnames({ invalid: !validEmail })}
                    maxLength={254}
                  />
                </label>
              </div>
            </div>
            <div className="contact-row">
              <label>
                Topic
                <Select
                  styles={customStyles}
                  components={{ DropdownIndicator, IndicatorSeparator }}
                  options={options}
                  placeholder={"Select a topic..."}
                  onChange={(e) => {
                    setTopic(e);
                  }}
                  className={classnames({ invalid: !validTopic })}
                  value={topic}
                />
              </label>
            </div>
            <div className="contact-row">
              <label>
                Message
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    !validMessage && setValidMessage(true);
                  }}
                  className={classnames({ invalid: !validMessage })}
                  maxLength={10000}
                ></textarea>
              </label>
            </div>
            <div className="contact-row contact-row_button">
              <button
                className="button"
                onClick={(e) => {
                  if (buttonMessage === "Success") {
                    return;
                  }
                  const [checkName, checkEmail, checkTopic, checkMessage] = [
                    name.length > 0,
                    email.length > 0 && validateEmail(email),
                    topic && topic["label"].length > 0,
                    message.length > 0,
                  ];

                  setValidName(checkName);
                  setValidEmail(checkEmail);
                  setValidTopic(checkTopic);
                  setValidMessage(checkMessage);

                  if (checkName && checkEmail && checkTopic && checkMessage) {
                    const sendPromise = mutationFunction({
                      variables: {
                        content: formatEmailBody(topic["label"], name, email, message),
                      },
                    });

                    sendPromise
                      .then((_data) => {
                        setButtonMessage("Success");
                        setTimeout(() => {
                          setButtonMessage("Submit");
                        }, 5000);
                        // console.log(data.MessageId);
                      })
                      .catch((_err) => {
                        // console.error(err, err.stack);
                      });
                    setName("");
                    setEmail("");
                    setTopic(null);
                    setMessage("");
                  }
                }}
              >
                <span className={buttonMessage === "Success" ? "elementToFadeInAndOut" : ""}>{buttonMessage}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default FeedbackForm;
