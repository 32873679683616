import { window } from "global";
import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { RiotAssetsProvider } from "@outplayed/riot-assets";
import AppDataInitializer from "./AppDataInitializer";
import AppLayout from "./AppLayout";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { removeCookie } from "@ugg/shared/utils/cookies";
import { AdblockDetector } from "adblock-detector";
import { UGGAssetsProvider } from "@ugg/shared/components/UGGAssetsProvider";
import { RampContainer } from "components/Ads/RampContainer";
import { GoogleAnalyticsWrapper } from "./GoogleAnalyticsWrapper";

import { ResponsiveProvider } from "@outplayed/responsive";
import { WINDOW_BREAKPOINTS_RANGE, DEFAULT_BREAKPOINT } from "@ugg/shared/constants/constants";

const AdblockDetectorContainer = () => {
  const [adblock, setAdblock] = useGlobal("adblock");
  const [isDetectingAdblock, setDetectingAdblock] = useGlobal("isDetectingAdblock");

  useEffect(() => {
    if (window) {
      const adbDetector = new AdblockDetector();
      const userHasAdblock = adbDetector.detect();
      setDetectingAdblock(false);
      setAdblock(userHasAdblock);
    }
  }, []);

  return null;
};

const UserAuth = () => {
  const [authToken] = useGlobal("authToken");
  const [loggingIn, setLoggingIn] = useGlobal("loggingIn");
  const { loading, data, error } = useQuery(GET_USER_STATE, { skip: !authToken });
  useEffect(() => {
    if (error) {
      console.log(error);
      if (window && authToken) {
        removeCookie("authToken");
      }
    }
    if (!loading) {
      setLoggingIn(false);
    }
  }, [loading]);

  return null;
};

const App = () => {
  const [allowCssTransition, setAllowCssTransition] = useGlobal("allowCssTransition");

  useEffect(() => {
    // Prevent animation to trigger after hydration
    setAllowCssTransition(true);
  }, []);

  return (
    <ResponsiveProvider breakpoints={WINDOW_BREAKPOINTS_RANGE} defaultBreakpoint={DEFAULT_BREAKPOINT}>
      {window && <AdblockDetectorContainer />}
      {window && <UserAuth />}
      {window && <RampContainer />}
      {window && <GoogleAnalyticsWrapper />}
      <RiotAssetsProvider staging={process.env.UGG_PUBLIC_RIOT_PATCH_ASSETS === "staging"} ssr>
        <UGGAssetsProvider staging={process.env.UGG_PUBLIC_RIOT_PATCH_ASSETS === "staging"}>
          <AppDataInitializer>
            <AppLayout />
          </AppDataInitializer>
        </UGGAssetsProvider>
      </RiotAssetsProvider>
    </ResponsiveProvider>
  );
};

export default App;
