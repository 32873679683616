import React, { Component, useEffect } from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";

import CustomInput from "components/CustomInput";
import RegionSelector from "@ugg/shared/components/common/RegionSelector";

const SummonerFinder = (props) => {
  const [clientRegion] = useGlobal("clientRegion");
  const {
    className,
    color,
    regionSelectorPosition,
    placeholder = "Riot ID",
    riotId,
    summonerRegion,
    onSummonerChange,
    onRegionChange,
    tabIndex,
    inputRef,
    autoFocus,
    error,
    dontDefaultUniversalRegion,
  } = props;

  useEffect(() => {
    // Default initial region to saved universal region
    if (!dontDefaultUniversalRegion && !summonerRegion) {
      onRegionChange(clientRegion);
    }
  }, []);

  const summonerFinderClassNames = classnames(
    "summoner-finder",
    {
      "summoner-finder_white": color === "white",
    },
    className,
  );

  const regionClassNames = classnames("summoner-finder_region-selector", {
    "summoner-finder_region-selector__right": regionSelectorPosition === "right",
  });

  return (
    <div className={summonerFinderClassNames}>
      <div className={regionClassNames}>
        <RegionSelector currentRegion={summonerRegion} onApply={onRegionChange} disableTooltip />
      </div>
      <CustomInput
        onCreateRef={inputRef}
        value={riotId}
        onChange={onSummonerChange}
        placeholder={placeholder}
        type="text"
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        error={error}
        hideErrorMessage
      />
    </div>
  );
};

export default SummonerFinder;
