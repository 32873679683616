import { getApiRegion } from "../utils/region-helpers";

export const API_VERSION = "1.5";
export const API_ROOT_PATH = `https://stats.u.gg/lol/${API_VERSION}`;
export const API_ROOT_PATH_V2 = `https://stats2.u.gg/lol/${API_VERSION}`;
export const API_UGG = "https://api.u.gg";

export function getApiUggRoot(region: string) {
  if (region) {
    return `https://${getApiRegion(region).toLowerCase()}.api.u.gg`;
  }

  return API_UGG;
}

export function getApiRoot(patch?: string) {
  if (!patch) {
    return API_ROOT_PATH_V2;
  }

  const patchSplit = patch.split("_");
  const season = parseInt(patchSplit[0] || "");
  const version = parseInt(patchSplit[1] || "");

  if (season < 8 || (season == 8 && version <= 23)) {
    return API_ROOT_PATH;
  }

  return API_ROOT_PATH_V2;
}
