import React, { useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "@ugitgud/legos/hooks/useLocalStorage";
import { useUggRouting } from "@ugitgud/legos/context/routing";

const gameMapping = {
  "2xko": "2xko",
  hd2: "helldivers-2",
  tft: "teamfight-tactics",
  val: "valorant",
  wow: "world-of-warcraft",
  deadlock: "deadlock",
  lol: "league-of-legends",
  siege: "rainbow-six-siege",
  "marvel-rivals": "marvel-rivals",
};

export type SupportedGames = keyof typeof gameMapping;

const DEFAULT_GAME = "lol";

const GAME_VISITED_LOCAL_STORAGE_KEY = "ugg-game-visted";
const NEW_USER_LOCAL_STORAGE_KEY = "ugg-new-user";
const GAME_CHANGE_GTAG_EVENT_NAME = "game-change";
const NEW_USER_GTAG_EVENT_NAME = "new-user";

/**
 * Handles pushing custom events to google analytics when the game changes or when a new user visits a game they havent seen. Must be wrapped in
 * `<UggRoutingProvider>` to work properly.
 *
 * @param param0
 * @returns
 */
export const UggGAEventProvider: React.FC<{ defaultGame?: SupportedGames }> = ({ defaultGame = DEFAULT_GAME }) => {
  const [isReady, setIsReady] = useState(false);
  const { path } = useUggRouting();
  const currentGame = useMemo(() => {
    if (typeof window === "undefined") {
      return gameMapping[defaultGame];
    }

    const splitPath = path.split("/");
    const currentSite = splitPath && splitPath[1];
    if (!currentSite) {
      return gameMapping[defaultGame];
    }
    const game = gameMapping[currentSite as SupportedGames];
    if (!game) {
      return gameMapping[defaultGame];
    }
    return game;
  }, [path, defaultGame, isReady]);
  const [gameVisited, setGameVisited] = useLocalStorage(`${GAME_VISITED_LOCAL_STORAGE_KEY}-${currentGame}`, false);
  const [newUser, setNewUser] = useLocalStorage(`${NEW_USER_LOCAL_STORAGE_KEY}-${currentGame}`, true);
  useEffect(() => {
    const gtagFunc =
      typeof (window as any).gtag !== "undefined"
        ? (window as any).gtag
        : function (...args: any) {
            (window as any)["dataLayer"]?.push(arguments);
          };
    if (typeof window === "undefined") {
      console.warn("Window is undefined, aborting analytics events.");
      return;
    }
    if (typeof gtagFunc === "undefined") {
      console.warn("Google tag manager not found, aborting.");
      return;
    }

    if (!currentGame || currentGame === "unmapped") {
      console.warn("Unknown game");
      return;
    }
    if (!gameVisited) {
      setGameVisited(true);
      gtagFunc("event", GAME_CHANGE_GTAG_EVENT_NAME, {
        event_label: `game-${currentGame}`,
      });
    }
    if (newUser) {
      setNewUser(false);
      gtagFunc("event", NEW_USER_GTAG_EVENT_NAME, {
        event_label: `new-user-${currentGame}`,
      });
    }
  }, [currentGame, newUser, gameVisited, setGameVisited, setNewUser]);
  useEffect(() => {
    setIsReady(true);
  }, []);
  return null;
};
