import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import ErrorBoundary from "@ugg/shared/components/common/ErrorBoundary";
import AppCrashErrorPage from "./AppCrashErrorPage";
import StackTrace from "stacktrace-js";
import {
  getCleanComponentStack,
  getBetterComponentStack,
  formatBetterComponentStack,
} from "@ugg/shared/utils/stacktrace-helpers";

export default function AppCrashErrorHandler(props: { children: React.ReactNode }) {
  const location = useLocation();
  const [loadingErrorReport, setLoadingErrorReport] = useState(true);
  const [errorStack, setErrorStack] = useState<{ message: string; stack: string } | undefined>(undefined);

  const logError = (error: Error, errorInfo: React.ErrorInfo) => {
    const originalError = error;
    const { componentStack = "" } = errorInfo || {};

    const getLogContext = (stackTraceError: StackTrace.StackFrame[] | string[], newComponentStack: string) => {
      return {
        errorType: "site_crash",
        error: {
          message: originalError?.message,
          stack: "    " + stackTraceError.join("\n    "),
        },
        errorInfo: {
          componentStack: newComponentStack,
          originalComponentStack: componentStack,
        },
      };
    };

    const sendDDErrorLog = (logContext: Record<any, any>) => {
      datadogLogs.logger.error(`Site crash: ${location.pathname} :: Error: ${originalError?.message}`, logContext);
    };

    const runStackTraceError = async (componentStack: string) => {
      return StackTrace.fromError(originalError)
        .then((stackTraceError) => {
          const logContext = getLogContext(stackTraceError, componentStack);
          setErrorStack(logContext?.error);
          sendDDErrorLog(logContext);
        })
        .catch((error) => {
          const logContext = getLogContext([originalError.stack || ""], componentStack);
          setErrorStack(logContext?.error);
          sendDDErrorLog(logContext);
        });
    };

    const cleanComponentStack = getCleanComponentStack(componentStack);
    const betterComponentStack = getBetterComponentStack(cleanComponentStack);
    betterComponentStack
      .then((stack) => {
        const betterComponentStackString = formatBetterComponentStack(stack);
        runStackTraceError(betterComponentStackString).then((res) => {
          setLoadingErrorReport(false);
        });
      })
      .catch((error) => {
        // could not create better component stack
        // use original component stack
        runStackTraceError(componentStack).then(() => {
          setLoadingErrorReport(false);
        });
      });
  };

  return (
    <ErrorBoundary
      sendError={logError}
      fallback={<AppCrashErrorPage loadingErrorReport={loadingErrorReport} errorStack={errorStack} />}
    >
      {props.children}
    </ErrorBoundary>
  );
}
