import React from "react";
import { costEfficiencyMap } from "@ugg/shared/components/Items/ItemsPage/cost-efficiency-map";

const CostAnalysisTooltip = (props) => {
  const { title, info1, info2, type } = props;

  if (type && costEfficiencyMap[type]) {
    return (
      <div className="tooltip-cost-analysis">
        <div>
          1 {type} = {costEfficiencyMap[type]} gold
        </div>
      </div>
    );
  }
  return (
    <div className="tooltip-cost-analysis">
      <div className="name mb-[12px]">{title}</div>
      <div className="mb-[12px]">{info1}</div>
      <div>{info2}</div>
    </div>
  );
};

export default CostAnalysisTooltip;
