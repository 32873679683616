import image404 from "@ugg/shared/assets/images/404-dark.png";

interface AppCrashErrorPageProps {
  loadingErrorReport: boolean;
  errorStack: { message: string; stack: string } | undefined;
}

export default function AppCrashErrorPage(props: AppCrashErrorPageProps) {
  const { loadingErrorReport, errorStack } = props;

  return (
    <div className="flex flex-col items-center justify-center mt-[130px] px-[12px] pb-[36px]">
      <img className="mb-[36px] w-[350px]" src={image404} />
      <div className="font-barlow text-[18px] font-medium text-accent-gray-100 text-center">
        <div>An error caused this feature to crash.</div>
        <div>
          Please contact us at <span className="underline text-white">support@u.gg</span>.
        </div>
        {loadingErrorReport && (
          <div className="spinthatshit-loader mt-[40px]">
            <div className="spinner" />
          </div>
        )}
      </div>
      {errorStack?.message && (
        <div className="mt-[40px] pr-[12px] py-[12px] w-min max-w-full rounded-[6px] border-[1px] border-[gray] text-[12px] overflow-y-auto">
          <pre className="select-text">{"    Error: " + errorStack?.message}</pre>
          <pre className="select-text">{errorStack?.stack}</pre>
        </div>
      )}
    </div>
  );
}
