import React, { useState } from "react";
import FeedbackForm from "./FeedbackForm";

import { ReactComponent as IconFeedback } from "svg/feedback-icon.svg";

const FeedbackIcon = (props) => {
  const [feedbackState, setFeedbackPopup] = useState(false);

  const toggleFeedbackPopup = () => {
    setFeedbackPopup(!feedbackState);
  };

  //Setting the state of the Feedback for the condition to render the Popup/Modal.
  return (
    <>
      <div
        className="flex items-center justify-center ml-[12px] w-[28px] h-[28px] rounded-[3px] cursor-pointer hover:bg-purple-100"
        onClick={toggleFeedbackPopup}
      >
        <IconFeedback width="14px" />
      </div>
      {feedbackState ? <FeedbackForm toggleFeedbackPopup={toggleFeedbackPopup} /> : null}
    </>
  );
};

export default FeedbackIcon;
