import { TIER_LIST_OPTIONS, TIER_LIST_DEFAULT, SPECIAL_TIER_LIST_DEFAULT } from "./filter-options/tier-lists/tier-list";
import { DUO_TIER_LIST_OPTIONS, DUO_TIER_LIST_DEFAULT } from "./filter-options/tier-lists/duo-tier-list";
import { COMBAT_OPTIONS, COMBAT_DEFAULT } from "./filter-options/tier-lists/combat-tier-list";
import { OBJECTIVES_OPTIONS, OBJECTIVES_DEFAULT } from "./filter-options/tier-lists/objectives-tier-list";
import { OVERVIEW_OPTIONS, OVERVIEW_DEFAULT, SPECIAL_OVERVIEW_DEFAULT } from "./filter-options/champions/overview";
import { ADV_BUILD_OPTIONS, ADV_BUILD_DEFAULT } from "./filter-options/champions/adv-builds";
import { ITEMS_OPTIONS, ITEMS_DEFAULT } from "./filter-options/champions/item-paths";
import { RUNES_OPTIONS, RUNES_DEFAULT } from "./filter-options/champions/rune-sets";
import { MATCHUPS_OPTIONS, MATCHUPS_DEFAULT } from "./filter-options/champions/matchups";
import { COUNTERS_OPTIONS, COUNTERS_DEFAULT } from "./filter-options/champions/counters";
import { DUOS_OPTIONS, DUOS_DEFAULT } from "./filter-options/champions/duos";
import { ALL_NEXUS_BLITZ_ROLE_OPTIONS, NEXUS_BLITZ_ROLE_OPTIONS } from "./filter-options/common";

import { TIER_LIST_PAGES, SPECIAL_TIER_LIST_PAGES } from "../pages/tier-list-pages";
import { CHAMPION_PAGES, SPECIAL_CHAMPION_PAGES } from "../pages/champion-pages";

import { RoleS } from "../utils/role-helpers";
import { QueueTypeS } from "../utils/queue-type-helpers";
import { RankS } from "../utils/rank-helpers";
import { FilterOption } from "../interfaces/filter-manager.interface";

type BaseParamOptions = {
  region: FilterOption[];
  queueType: { value: QueueTypeS | string; label: string; path?: string }[];
  patch: { options: string; max: number };
  rank: FilterOption[];
  allChampions?: { value: string; label: string }[];
  duo?: FilterOption[];
  role?: FilterOption[];
  default: {
    queueType: QueueTypeS | string;
    region: string;
    patch?: string;
    role?: RoleS;
    rank?: RankS | string;
  };
};

type ExtendedParamOptions = Partial<Pick<BaseParamOptions, "region" | "rank" | "allChampions">> &
  Omit<BaseParamOptions, "region" | "rank" | "allChampions"> & {
    exemptions?: string[];
  };

type ValidParamsType = {
  [K in TIER_LIST_PAGES]: BaseParamOptions;
} & {
  [K in SPECIAL_TIER_LIST_PAGES | CHAMPION_PAGES | SPECIAL_CHAMPION_PAGES]: ExtendedParamOptions;
};

export const validParams: ValidParamsType = {
  [TIER_LIST_PAGES.DEFAULT_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.ALL },
  },
  [TIER_LIST_PAGES.TOP_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.TOP },
  },
  [TIER_LIST_PAGES.JUNGLE_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.JUNGLE },
  },
  [TIER_LIST_PAGES.MID_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.MIDDLE },
  },
  [TIER_LIST_PAGES.ADC_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.BOTTOM },
  },
  [TIER_LIST_PAGES.SUPPORT_TIER_LIST]: {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: { ...TIER_LIST_DEFAULT, role: RoleS.SUPPORT },
  },
  [SPECIAL_TIER_LIST_PAGES.PICK_URF_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.PICK_URF },
  },
  [SPECIAL_TIER_LIST_PAGES.ARURF_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.ARURF },
  },
  [SPECIAL_TIER_LIST_PAGES.ARAM_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    region: TIER_LIST_OPTIONS.region,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.ARAM },
  },
  [SPECIAL_TIER_LIST_PAGES.NEXUS_BLITZ_TIER_LIST]: {
    role: ALL_NEXUS_BLITZ_ROLE_OPTIONS,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.NEXUS_BLITZ, role: RoleS.ALL },
  },
  [SPECIAL_TIER_LIST_PAGES.ONE_FOR_ALL_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.ONE_FOR_ALL, role: RoleS.MIDDLE },
  },
  [SPECIAL_TIER_LIST_PAGES.ARENA_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.ARENA, role: RoleS.NONE },
  },
  [SPECIAL_TIER_LIST_PAGES.ARENA_DUO_TIER_LIST]: {
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    default: { ...SPECIAL_TIER_LIST_DEFAULT, queueType: QueueTypeS.ARENA_DUO },
  },
  [TIER_LIST_PAGES.DUO_TIER_LIST]: {
    region: DUO_TIER_LIST_OPTIONS.region,
    queueType: DUO_TIER_LIST_OPTIONS.queueType,
    patch: DUO_TIER_LIST_OPTIONS.patch,
    rank: DUO_TIER_LIST_OPTIONS.rank,
    duo: DUO_TIER_LIST_OPTIONS.duo,
    default: DUO_TIER_LIST_DEFAULT,
  },
  [TIER_LIST_PAGES.COMBAT_TIER_LIST]: {
    region: COMBAT_OPTIONS.region,
    queueType: COMBAT_OPTIONS.queueType,
    patch: COMBAT_OPTIONS.patch,
    rank: COMBAT_OPTIONS.rank,
    role: COMBAT_OPTIONS.role,
    allChampions: COMBAT_OPTIONS.allChampions,
    default: COMBAT_DEFAULT,
  },
  [TIER_LIST_PAGES.OBJECTIVES_TIER_LIST]: {
    region: OBJECTIVES_OPTIONS.region,
    queueType: OBJECTIVES_OPTIONS.queueType,
    patch: OBJECTIVES_OPTIONS.patch,
    rank: OBJECTIVES_OPTIONS.rank,
    role: OBJECTIVES_OPTIONS.role,
    allChampions: OBJECTIVES_OPTIONS.allChampions,
    default: OBJECTIVES_DEFAULT,
  },
  [CHAMPION_PAGES.OVERVIEW]: {
    region: OVERVIEW_OPTIONS.region,
    queueType: OVERVIEW_OPTIONS.queueType,
    rank: OVERVIEW_OPTIONS.rank,
    role: OVERVIEW_OPTIONS.role,
    exemptions: ["opp"],
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARAM]: {
    queueType: OVERVIEW_OPTIONS.queueType,
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.ARAM,
      rank: RankS.OVERALL,
      role: RoleS.NONE,
    },
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARURF]: {
    queueType: OVERVIEW_OPTIONS.queueType,
    patch: OVERVIEW_OPTIONS.patch,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.ARURF,
      rank: RankS.OVERALL,
      role: RoleS.NONE,
    },
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_PICK_URF]: {
    queueType: OVERVIEW_OPTIONS.queueType,
    patch: OVERVIEW_OPTIONS.patch,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.PICK_URF,
      rank: RankS.OVERALL,
      role: RoleS.NONE,
    },
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ONE_FOR_ALL]: {
    queueType: OVERVIEW_OPTIONS.queueType,
    patch: OVERVIEW_OPTIONS.patch,
    role: OVERVIEW_OPTIONS.role,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.ONE_FOR_ALL,
      role: RoleS.MIDDLE,
    },
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_NEXUS_BLITZ]: {
    role: NEXUS_BLITZ_ROLE_OPTIONS,
    queueType: OVERVIEW_OPTIONS.queueType,
    patch: OVERVIEW_OPTIONS.patch,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.NEXUS_BLITZ,
      rank: RankS.OVERALL,
    },
  },
  [SPECIAL_CHAMPION_PAGES.OVERVIEW_ARENA]: {
    queueType: OVERVIEW_OPTIONS.queueType,
    patch: OVERVIEW_OPTIONS.patch,
    default: {
      ...SPECIAL_OVERVIEW_DEFAULT,
      queueType: QueueTypeS.ARENA,
      rank: RankS.OVERALL,
      role: RoleS.NONE,
    },
  },
  [CHAMPION_PAGES.ITEM_PATHS]: {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  [CHAMPION_PAGES.ITEMS]: {
    region: ITEMS_OPTIONS.region,
    queueType: ITEMS_OPTIONS.queueType,
    patch: ITEMS_OPTIONS.patch,
    rank: ITEMS_OPTIONS.rank,
    role: ITEMS_OPTIONS.role,
    default: ITEMS_DEFAULT,
  },
  [CHAMPION_PAGES.SPELLS_ABILITIES]: {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  [CHAMPION_PAGES.RUNE_SETS]: {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  [CHAMPION_PAGES.RUNES_TABLE]: {
    region: RUNES_OPTIONS.region,
    queueType: RUNES_OPTIONS.queueType,
    patch: RUNES_OPTIONS.patch,
    rank: RUNES_OPTIONS.rank,
    role: RUNES_OPTIONS.role,
    default: RUNES_DEFAULT,
  },
  [CHAMPION_PAGES.MATCHUPS]: {
    region: MATCHUPS_OPTIONS.region,
    queueType: MATCHUPS_OPTIONS.queueType,
    patch: MATCHUPS_OPTIONS.patch,
    rank: MATCHUPS_OPTIONS.rank,
    role: MATCHUPS_OPTIONS.role,
    allChampions: MATCHUPS_OPTIONS.allChampions,
    default: MATCHUPS_DEFAULT,
  },
  [CHAMPION_PAGES.COUNTERS]: {
    region: COUNTERS_OPTIONS.region,
    queueType: COUNTERS_OPTIONS.queueType,
    patch: COUNTERS_OPTIONS.patch,
    rank: COUNTERS_OPTIONS.rank,
    role: COUNTERS_OPTIONS.role,
    default: COUNTERS_DEFAULT,
  },
  [CHAMPION_PAGES.DUOS]: {
    region: DUOS_OPTIONS.region,
    queueType: DUOS_OPTIONS.queueType,
    patch: DUOS_OPTIONS.patch,
    rank: DUOS_OPTIONS.rank,
    role: DUOS_OPTIONS.role,
    allChampions: DUOS_OPTIONS.allChampions,
    default: DUOS_DEFAULT,
  },
};

// https://u.gg/lol/champions/caitlyn/build?google_preview=XNvy0N2m7PgY6v-yhwYw6pv[…]020501169&gdfp_req=1&lineItemId=5739269658&creativeId=138356381980

export const global_exemptions = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "url",
  "gclid",
  "gtm",
  "google_preview",
  "gdfp_req",
  "lineItemId",
  "creativeId",
  "googfc",
  "iframe",
  "aps_debug",
  "aps_preview",
  "aps_pause",
  // for lngtd. Remove after they are done
  "krg_qa",
  "ad_id",
  "google_preview",
  "iu",
  "gdfp_req",
  "lineItemId",
  "creativeId",
  "testbidder",
];
