import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import Masthead from "components/Masthead";
import MainContent from "components/MainContent";
import AppSideNav from "components/SideNav/AppSideNav";
import { GameNav } from "@ugitgud/legos/components/nav/GameNav/GameNav";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

// For login and signup pages we don't want to display sideNav or Masthead
function isAccountPage(pathname: string) {
  const accountPages: string[] = [
    AppRoutes.SIGNUP,
    AppRoutes.LOGIN,
    AppRoutes.RECOVER_ACCOUNT,
    AppRoutes.RESET_PASSWORD,
    AppRoutes.VERIFICATION,
  ];
  return accountPages.includes(pathname);
}

function isPremiumPage(pathname: string) {
  return pathname.match(/^\/boost\//);
}

const AppLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const [sideNavOpen, setSideNavOpen] = useState(false);

  useEffect(() => {
    if (location.pathname !== location.pathname.toLowerCase()) {
      history.replace(location.pathname.toLowerCase() + location.search);
    }
  }, [location.pathname]);

  return (
    <div
      className={classNames(
        "max-w-[100%] dark",
        "router-container", // DO NOT REMOVE 'router-container' class. REQUIRED FOR ADS!!!!
      )}
    >
      <GameNav selectedGame="lol" className="fixed top-0 left-0 z-[999]" />
      {!isAccountPage(location.pathname) && <Masthead sideNavOpen={sideNavOpen} setSideNavOpen={setSideNavOpen} />}
      <div id="page-content" className="flex">
        {/* DO NOT REMOVE 'page-content' id. REQUIRED FOR ADS!!!!  */}
        {!isAccountPage(location.pathname) && !isPremiumPage(location.pathname) && (
          <AppSideNav open={sideNavOpen} setOpen={setSideNavOpen} />
        )}
        <div className="w-full">
          {/* DO NOT REMOVE DIV. REQUIRED FOR ADS!!!!  */}
          <MainContent />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
