import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { getUrl } from "@outplayed/riot-assets";
import { parseQueryString } from "@ugg/shared/utils/url-query-params";
import { VERIFY_RIOT_CODE } from "@ugg/shared/api/graphql/queries";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { AppRoutes } from "@ugg/shared/routes/app-routes";

const VerificationIconPage = (props) => {
  const { getProfileIconImg } = getRiotAssetsContext();
  const [errorOnRiotCode, setErrorOnRiotCode] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = parseQueryString(location.search.substring(1));
  const continueURL = searchParams.continue;

  const [verifyCode, { loading, error: riotCodeError, data: riotCodeData }] = useLazyQuery(VERIFY_RIOT_CODE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.verifyRiotCode.success) {
        setErrorOnRiotCode(true);
      }
    },
    onError: (error) => {
      setErrorOnRiotCode(true);
    },
  });

  const { loadingUserState, error, data } = useQuery(GET_USER_STATE, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      if (!loading && riotCodeData) {
        if (!continueURL) {
          history.push(AppRoutes.HOME);
        } else if (continueURL === "settings") {
          history.push(AppRoutes.SETTINGS_ACCOUNT);
        }
      }
    },
    skip: !riotCodeData || !riotCodeData.verifyRiotCode.success,
  });

  return (
    <div className="code-copy-page">
      <div className="small-header" style={{ marginBottom: "12px" }}>
        Verify Your Account
      </div>
      <div className="step-text" style={{ marginBottom: "12px" }}>
        Step 2 of 2
      </div>
      <div className="basic-text">
        <span>1.&nbsp;</span> Open your League client and login
      </div>
      <div className="basic-text">
        <span>2.&nbsp;</span> Click on your profile icon in the top right.
      </div>
      <img className="img1 mt-[24px]" src={getUrl("lol/performance_analysis/images/profile_verification_icon.jpg")} />
      <div className="basic-text">
        <span>3.&nbsp;</span> Save the below icon as your profile icon.
      </div>
      <img className="img2 mt-[24px]" src={getProfileIconImg(props.iconID)} />
      <div className="basic-text">
        <span>4.&nbsp;</span> Once you've saved your profile icon, come back to this page and click the button below.
      </div>
      <ButtonSuccess
        className="btn-light-blue"
        label="Verify"
        isLoading={loading}
        onClick={verifyCode}
        style={{
          height: 42,
          marginTop: 14,
        }}
      />
      {(riotCodeError || errorOnRiotCode) && (
        <div className="riot-code-verification-error">
          There was an error attempting to complete verification. Try restarting your League of Legends client and verify again.
        </div>
      )}
    </div>
  );
};

export default VerificationIconPage;
