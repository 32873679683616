import { useMemo } from "react";
import { match } from "path-to-regexp";
import { useLocation } from "react-router-dom";
import { useResponsive } from "@outplayed/responsive";
import { Ramp, TaglessAdUnits } from "@outplayed/ramp";
import { AppRoutes, RouterPaths } from "@ugg/shared/routes/app-routes";
import { WINDOW_BREAKPOINTS_RANGE } from "@ugg/shared/constants/constants";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";

export const RampContainer = () => {
  const location = useLocation();
  const { currentBreakpoint } = useResponsive();
  const { loading, data, error } = useQuery(GET_USER_STATE);

  const adUnits = useMemo(() => {
    for (let path in adUnitsByPage) {
      if (match(path)(location.pathname)) {
        return adUnitsByPage[path][currentBreakpoint as WindowBreakpoints] || adUnitsByPage[path].default;
      }
    }
    return defaultAdUnits;
  }, [location.pathname, currentBreakpoint]);

  if (loading || data?.getState.premium.isPremium) {
    return null;
  }

  return (
    <Ramp
      PUB_ID={process.env.UGG_PUBLIC_PW_PUB_ID}
      WEBSITE_ID={process.env.UGG_PUBLIC_PW_WEBSITE_ID}
      pathname={location.pathname}
      search={location.search}
      pwUnits={adUnits}
    />
  );
};

function getAdUnitsPages(routes: Readonly<string[]>, adUnits: AdUnitsConfig) {
  const map: { [key: string]: AdUnitsConfig } = {};
  for (let route of routes) {
    map[route] = adUnits;
  }
  return map;
}

const defaultAdUnits = [
  { type: TaglessAdUnits.BottomRail },
  { type: TaglessAdUnits.CornerAdVideo },
  { type: TaglessAdUnits.FlexLeaderboard },
];

type WindowBreakpoints = keyof typeof WINDOW_BREAKPOINTS_RANGE;
type AdUnitsConfig = { [key in WindowBreakpoints]?: Array<{ type: TaglessAdUnits }> } & {
  default: Array<{ type: TaglessAdUnits }>;
};

const adUnitsByPage: { [key: string]: AdUnitsConfig } = {
  [AppRoutes.HOME]: {
    default: [{ type: TaglessAdUnits.BottomRail }, { type: TaglessAdUnits.CornerAdVideo }],
  },
  ...getAdUnitsPages(
    [
      AppRoutes.LOGIN,
      AppRoutes.SIGNUP,
      AppRoutes.RECOVER_ACCOUNT,
      AppRoutes.RESET_PASSWORD,
      AppRoutes.VERIFICATION,
      AppRoutes.FAQ,
      AppRoutes.PRIVACY_POLICY,
      AppRoutes.PRIVACY_SHIELD,
      AppRoutes.CAREERS,
      AppRoutes.TOS,
    ],
    {
      default: [{ type: TaglessAdUnits.BottomRail }, { type: TaglessAdUnits.CornerAdVideo }],
    },
  ),
  // Leaderboards
  ...getAdUnitsPages([AppRoutes.LEADERBOARD, AppRoutes.CHAMPION_LEADERBOARD], {
    default: defaultAdUnits,
    DESKTOP_MEDIUM: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
    DESKTOP_LARGE: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
  }),
  // Tier Lists
  ...getAdUnitsPages(RouterPaths.TIER_LIST, {
    default: defaultAdUnits,
    DESKTOP_MEDIUM: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
    DESKTOP_LARGE: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
  }),
  // Champions
  ...getAdUnitsPages(
    [
      AppRoutes.CHAMPION_HOME,
      AppRoutes.CHAMPION_PROFILE_BUILD,
      AppRoutes.CHAMPION_PROFILE_BUILD_ARAM,
      AppRoutes.CHAMPION_PROFILE_BUILD_URF,
      AppRoutes.CHAMPION_PROFILE_BUILD_PICK_URF,
      AppRoutes.CHAMPION_PROFILE_BUILD_ARURF,
      AppRoutes.CHAMPION_PROFILE_BUILD_NEXUS_BLITZ,
      AppRoutes.CHAMPION_PROFILE_BUILD_ONE_FOR_ALL,
      AppRoutes.CHAMPION_PROFILE_BUILD_ARENA,
      AppRoutes.CHAMPION_PROFILE_MATCHUPS,
      AppRoutes.CHAMPION_PROFILE_COUNTERS,
      AppRoutes.CHAMPION_PROFILE_ITEM_PATHS,
      AppRoutes.CHAMPION_PROFILE_SPELLS_ABILITIES,
      AppRoutes.CHAMPION_PROFILE_DUOS,
    ],
    {
      default: defaultAdUnits,
      DESKTOP_MEDIUM: [
        // { type: TaglessAdUnits.LeftRail },
        // { type: TaglessAdUnits.RightRail },
        { type: TaglessAdUnits.BottomRail },
        { type: TaglessAdUnits.CornerAdVideo },
        { type: TaglessAdUnits.FlexLeaderboard },
      ],
      DESKTOP_LARGE: [
        // { type: TaglessAdUnits.LeftRail },
        // { type: TaglessAdUnits.RightRail },
        { type: TaglessAdUnits.BottomRail },
        { type: TaglessAdUnits.CornerAdVideo },
        { type: TaglessAdUnits.FlexLeaderboard },
      ],
    },
  ),
  // Profiles
  ...getAdUnitsPages([AppRoutes.PROFILE_OVERVIEW, AppRoutes.PROFILE_CHAMPION_STATS, AppRoutes.PROFILE_LIVE_GAME], {
    default: defaultAdUnits,
    DESKTOP_MEDIUM: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
    DESKTOP_LARGE: [
      // { type: TaglessAdUnits.LeftRail },
      // { type: TaglessAdUnits.RightRail },
      { type: TaglessAdUnits.BottomRail },
      { type: TaglessAdUnits.CornerAdVideo },
      { type: TaglessAdUnits.FlexLeaderboard },
    ],
  }),
  [AppRoutes.UGG_APP_SPLASH]: {
    default: [],
  },
  [AppRoutes.MARVEL_RIVALS]: {
    default: [],
  },
};
