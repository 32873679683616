import { window } from "global";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { usePageTitleAndDesc } from "@ugg/shared/utils/seo-helpers";

const PremiumFAQ = (props) => {
  const getPageTitleAndDesc = usePageTitleAndDesc();

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-content-container content-side-padding">
      {getPageTitleAndDesc("faq")}
      <div className="text-content">
        <h1>BOOST FAQs</h1>
        <h2>What regions or currencies are supported?</h2>
        <p>
          We use Stripe to process all payments. Stripe is available in 34 countries{" "}
          <a href={"https://stripe.com/global"}>Stripe.com</a>. We are working to implement PayPal as a payment option! Please
          email us at{" "}
          <a className="underline" href="mailto:info@u.gg">
            info@u.gg
          </a>{" "}
          if you’d like to recommend any other payment providers.
        </p>
        <h2>What made my payment fail?</h2>
        <p>
          Insufficient funds is the most common reason for failed payments. The other common reasons are incorrectly typed card
          details and financial institutions simply denying the transaction. If you have the transaction amount available and know
          you typed the information correctly, please contact your bank to discuss your specific transaction and account details.
        </p>
        <h2>Will I be able to change my account type or billing after I create an account?</h2>
        <p>Yes. Go to Settings &gt; BOOST to change both.</p>
        <h2>Can I change my payment method after I sign up for BOOST?</h2>
        <p>Yes. Go to Settings &gt; BOOST &gt; Change Card.</p>
        <h2>The fourth feature says “Our Love and Gratitude”, but just how much?</h2>
        <p>
          If you will have us, we, U.GG, take you, LeBronzeJames, to be our lawfully wedded BOOST user—to have and to hold, from
          this day forward, for better, for worse, for richer, for poorer, in sickness and in health, until your BOOST
          cancellation do us apart. But really, we’re extremely grateful for the support from each and every one of you. We’re
          serious when we say more features are coming, from a comprehensive combo database to unique profile analysis to better
          cosmetics to custom match metrics, and much more. It’s going to be a busy few months, and we appreciate you being on the
          ride with us!
        </p>
      </div>
    </div>
  );
};

export default PremiumFAQ;
