import { window } from "global";
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import _debounce from "lodash/debounce";
import { useGlobal } from "reactn";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Autosuggest from "react-autosuggest";
import { useBestSearchMatch } from "@ugg/shared/api/requests/search-pages";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import _uniqby from "lodash.uniqby";
import { getBlockImg } from "@ugg/shared/utils/region-helpers";
import { useSearchBarPages } from "lib/hooks/searchHooks";
import { generateBuildAndRunes } from "@ugg/shared/utils/filter-preference-helpers";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { stripMultiSearchBar } from "./MultiSearch/multi-search-filter";
import { ItemLink } from "@ugg/shared/components/Items/ItemLink";
import { ReactComponent as SearchIconWhite } from "svg/search-icon-2-white.svg";
import { ReactComponent as SearchIconGrey } from "svg/magnifying-glass-searchbar.svg";
import { ReactComponent as PaperWriting } from "svg/paper-writing.svg";

import { useClientSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { useClientRiotIdBySummonerName } from "@ugg/shared/api/requests/summoner-profiles/riot-id-by-summoner-name";
import { getProfileOverviewUrl, getOldProfileOverviewUrl, AppRoutes } from "@ugg/shared/routes/app-routes";
import { VALID_SEARCH_SECTIONS } from "@ugg/shared/api/requests/search-pages";
import { useLazyPlayerInfoSuggestions } from "@ugg/shared/api/requests/summoner-profiles/player-info-suggestions";

import SearchBarRegionSelector from "@ugg/shared/components/common/SearchBar/SearchBarRegionSelector";

export const useCompare = (val) => {
  const prevVal = usePrevious(val);

  return prevVal !== val;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function filterSuggestionPages(section, regex) {
  let filteredPages = section.pages.filter((page) => regex.test(page.key));
  if (filteredPages.length > 4) {
    filteredPages = _uniqby(filteredPages, (page) => page.displayName);
  }

  // If there's only one champ build result, make a result for each tag
  if (
    section.title.toLowerCase() === VALID_SEARCH_SECTIONS.BUILD &&
    filteredPages.length === 1 &&
    (filteredPages[0].tags || []).length > 1
  ) {
    const page = filteredPages[0];
    const expandedPages = page.tags.map((tag) => ({ ...page, tags: [tag] }));
    return {
      title: section.title,
      pages: expandedPages,
    };
  }

  return {
    title: section.title,
    pages: filteredPages,
  };
}

function useSuggestions() {
  const { data: searchBarPages, loading: loadingSearchBarPages, error: errorSearchBarPages } = useSearchBarPages();

  return (value, clientRegion, playerInfoSuggestions) => {
    if (!searchBarPages || loadingSearchBarPages || errorSearchBarPages) {
      return [];
    }

    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") return [];
    const regex = new RegExp("^" + escapedValue, "i");

    let suggestions = searchBarPages
      .map((section) => filterSuggestionPages(section, regex))
      .filter((section) => section.pages.length > 0);

    let [inputRiotUserName, inputRiotTagLine] = (value || "").toLowerCase().split("#");
    inputRiotUserName = inputRiotUserName.trim();

    let finalSuggestions = [
      {
        title: "Summoner Profile",
        pages:
          playerInfoSuggestions?.length > 0
            ? playerInfoSuggestions.map(({ puuid, riotUserName, riotTagLine, iconId, summonerLevel }) => ({
                key: String(puuid),
                uniqId: `${clientRegion}_${riotUserName}_${riotTagLine}`.toLowerCase(),
                displayName: `${riotUserName} #${riotTagLine}`,
                url: getProfileOverviewUrl(clientRegion, riotUserName, riotTagLine),
                regionId: clientRegion,
                section: VALID_SEARCH_SECTIONS.SUMMONER,
                iconId,
                summonerLevel,
              }))
            : [
                {
                  key: "summonerProfile",
                  displayName: value,
                  url:
                    inputRiotUserName && inputRiotTagLine
                      ? getProfileOverviewUrl(clientRegion, inputRiotUserName, inputRiotTagLine)
                      : getOldProfileOverviewUrl(clientRegion, value),
                  regionId: clientRegion,
                  section: VALID_SEARCH_SECTIONS.SUMMONER,
                },
              ],
      },
    ];

    if (suggestions.length > 0) {
      finalSuggestions.unshift(suggestions.shift());
      finalSuggestions = finalSuggestions.concat(suggestions);
    }

    return finalSuggestions;
  };
}

function getSuggestionValue(suggestion) {
  return suggestion.displayName;
}

function useRenderSuggestion() {
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { getProfileIconImg } = getRiotAssetsContext();

  return (suggestion, { query, _isHighlighted }) => {
    let img;
    if (suggestion.section === "summoner") {
      img = <img src={getProfileIconImg(suggestion.iconId || 1)} className="summoner-icon" />;
    } else if (suggestion.section === "items") {
      img = <ItemLink itemId={suggestion.itemId} isSprite />;
    } else if (suggestion.section === "pages") {
      img = <PaperWriting style={{ height: 20, width: 20 }} />;
    } else {
      img = (
        <div className="champion-icon" style={{ overflow: "hidden", borderRadius: "2px" }}>
          {getChampionImgFromSprite(suggestion.champId, { size: 20 })}
        </div>
      );
    }

    let typeImg;
    if (suggestion.section === "pro-build") {
      typeImg = (
        <img className="page-type" src={"https://static.bigbrain.gg/assets/ugg/icons/search_probuilds.svg"} alt="Pro Build" />
      );
    } else if (suggestion.section === "counters") {
      typeImg = (
        <img className="page-type" src={"https://static.bigbrain.gg/assets/ugg/icons/search_counters.svg"} alt="Counters" />
      );
    }

    return (
      <div className="render-suggestion">
        <div>
          {typeImg}
          {img}
        </div>
        <div className="suggest-text">
          {suggestion.displayName}
          <span className="suggest-text-secondary">
            {suggestion.section == "summoner" && suggestion.summonerLevel && `Lvl ${suggestion.summonerLevel}`}
          </span>
        </div>
        {suggestion.section == "summoner" && <img className="suggest-right" src={getBlockImg(suggestion.regionId, true)} />}
        {suggestion.tags && (
          <div className={`suggest-tag-container ${suggestion.section}`}>
            {(suggestion.tags || []).map((tag) => (
              <Link key={tag.id} className={`tag ${tag.id} ${tag.active ? "active" : ""}`} to={tag.url}>
                {tag.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };
}

function renderSectionTitle(section) {
  return <strong>{section.title}</strong>;
}

function getSectionSuggestions(section) {
  return section?.pages || [];
}

function getRecentlySearched() {
  if (window) {
    const recentlySearched = localStorage.getItem("recentlySearched");
    if (recentlySearched !== null) {
      return [
        {
          title: "Recently Searched",
          pages: JSON.parse(recentlySearched),
        },
      ];
    }
  }

  return [];
}

function setRecentlySearched(entry, filterBy) {
  const MAX_RESULTS = 6;
  if (window) {
    const recentlySearched = localStorage.getItem("recentlySearched");
    if (recentlySearched === null) {
      localStorage.setItem("recentlySearched", JSON.stringify([entry]));
    } else {
      const parsed = JSON.parse(recentlySearched);
      const filtered = parsed.filter(
        filterBy ||
          ((el) => {
            if ("uniqId" in entry && entry.section === VALID_SEARCH_SECTIONS.SUMMONER) {
              return "uniqId" in el ? el.uniqId !== entry.uniqId : true;
            }

            return el.displayName !== entry.displayName;
          }),
      );
      filtered.unshift(entry);
      localStorage.setItem("recentlySearched", JSON.stringify(filtered.slice(0, MAX_RESULTS)));
    }
  }
}

const SearchBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const inputRef = useRef();
  const initInteracting = useRef(false);
  const [init, setInit] = useState(false);
  const [isInteracting, setInteraction] = useState(false);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState(getRecentlySearched());
  const [highlightedSuggestion, setHighlightedSuggestion] = useState(null);
  const [lastPage, setLastPage] = useState(undefined);
  const [lastSearchValue, setLastSearchValue] = useState(undefined);
  const [searchResults, setSearchResults] = useState(undefined);
  const [searching, setSearching] = useState(false);
  const [multiSearching, setMultisearching] = useState(false);
  const [isCommaList, setIsCommaList] = useState(false);
  const [isParsedText, setIsParsedText] = useState(false);
  const [clientRegion] = useGlobal("clientRegion");
  const [playerInfoSuggestions, setPlayerInfoSuggestions] = useState([]);
  const playerInfoSuggestionsChanged = useCompare(JSON.stringify(playerInfoSuggestions));

  const { data: userState } = useQuery(GET_USER_STATE);
  const { filterDefaults } = (userState && userState.getState) || {};
  const championSearchParams = useMemo(() => generateBuildAndRunes(filterDefaults?.filterBuildsAndRunes || {}), [filterDefaults]);

  const getSummonerProfile = useClientSummonerProfileInitSimple();
  const getRiotIdBySummonerName = useClientRiotIdBySummonerName();

  const [getPlayerInfoSuggestions, { loading: playerInfoSuggestionsLoading, data: playerInfoSuggestionsData = {} }] =
    useLazyPlayerInfoSuggestions({
      ssr: false,
    });

  const debouncedGetPlayerInfoSuggestions = useCallback(_debounce(getPlayerInfoSuggestions, 200), []);

  const {
    theme,
    autoFocus,
    placeholder,
    isMasthead,
    noDropdown,
    defaultValue,
    multisearchOnly,
    goToLiveGame,
    onSearchComplete = () => {},
  } = props;

  const getBestSearchMatch = useBestSearchMatch();
  const getSuggestions = useSuggestions();
  const renderSuggestion = useRenderSuggestion();

  const changeUrl = (page, lastSearchValue) => {
    if (page.targetHref) {
      window && window.open(page.url);
    } else if (page.url) {
      setLastSearchValue(lastSearchValue);
      setLastPage(page.url);
      if (location.pathname !== page.url) {
        window && window.scrollTo(0, 0);
        onSearchComplete();
        history.push({
          pathname: page.url,
          search: championSearchParams || "",
        });
      }
    }
  };

  const search = (val) => {
    const commaList = val
      .split(",")
      .map((entry) => entry.trim())
      .filter((entry) => entry !== "");
    const { isMultiSearch, names } = stripMultiSearchBar(val);
    if (commaList.length > 1 || multisearchOnly) {
      setIsCommaList(true);
      setMultisearching(true);
    } else if (isMultiSearch && names?.length > 0) {
      setIsParsedText(true);
      setMultisearching(true);
    } else {
      setIsCommaList(false);
      if (val.trim() === "") return;
      const searchValue = commaList[0]; // has one valid search value
      const matches = getBestSearchMatch(searchValue);
      const noNicknames = matches.filter((match) => !match.item.isNickname);
      // Check exact name
      const hasExactMatch = matches.length > 0 && Math.round(matches[0].score * 1000000) / 1000000 === 0;
      // Check exact nickname
      const noNicknameMatch = noNicknames.length > 0 && noNicknames[0];
      let resToUse = hasExactMatch && matches[0];
      // Check name substring match
      if (noNicknameMatch) {
        const strippedMatchValue = noNicknameMatch.item.key.toLowerCase();
        const strippedSearchValue = searchValue.toLowerCase();
        if (strippedMatchValue.indexOf(strippedSearchValue) === 0 && noNicknameMatch.item.isDefaultPage === true) {
          resToUse = noNicknameMatch;
        }
      }

      if (resToUse) {
        changeUrl(resToUse.item, searchValue);
        // Add to recently recently searched
        if (window) {
          const recentlySearched = localStorage.getItem("recentlySearched");
          const newEntry = resToUse.item;
          newEntry.champId = newEntry.id;
          if (recentlySearched === null) {
            localStorage.setItem("recentlySearched", JSON.stringify([newEntry]));
          } else {
            const parsed = JSON.parse(recentlySearched);
            const filtered = parsed.filter((item) => item.displayName !== resToUse.item.displayName);
            filtered.unshift(newEntry);
            localStorage.setItem("recentlySearched", JSON.stringify(filtered.slice(0, 6)));
          }
        }
      } else {
        setSearching(true);
        // riotUserName === summonerName if riotTagLine is missing
        let [inputRiotUserName, inputRiotTagLine] = (val || "").toLowerCase().split("#");
        inputRiotUserName = inputRiotUserName.trim();
        if (!inputRiotTagLine) {
          getRiotIdBySummonerName(clientRegion, inputRiotUserName).then((res) => {
            const { data } = res;
            const { riotUserName, riotTagLine } = data?.riotIdBySummonerName || {};
            if (riotUserName && riotTagLine) {
              history.push(getProfileOverviewUrl(clientRegion, riotUserName, riotTagLine));
            } else {
              history.push({
                pathname: getOldProfileOverviewUrl(clientRegion, inputRiotUserName),
                state: { search: 404, searchResults: searchResults },
              });
            }
            setSearching(false);
          });
        } else {
          getSummonerProfile(clientRegion, inputRiotUserName, inputRiotTagLine).then((res) => {
            const { data } = res;
            if (data?.profileInitSimple) {
              const { playerInfo } = data.profileInitSimple;
              const { regionId, riotUserName, riotTagLine, iconId, summonerLevel } = playerInfo;
              const uniqId = `${regionId}_${riotUserName}_${riotTagLine}`.toLowerCase();
              const newEntry = {
                key: uniqId,
                uniqId,
                displayName: `${riotUserName} #${riotTagLine}`,
                regionId: regionId,
                section: VALID_SEARCH_SECTIONS.SUMMONER,
                url: getProfileOverviewUrl(regionId, riotUserName, riotTagLine),
                iconId,
                summonerLevel,
              };

              setSearchResults(matches);
              setLastSearchValue(searchValue);
              setSearching(true);
              setRecentlySearched(newEntry);
              setSearching(false);
              onSearchComplete();

              let navPath = getProfileOverviewUrl(clientRegion, riotUserName.trim(), riotTagLine);
              if (goToLiveGame) {
                navPath = getProfileLiveGameUrl(clientRegion, riotUserName.trim(), riotTagLine);
              }
              history.push({ pathname: navPath });
            }
          });
        }
      }
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      let searchBar = inputRef && inputRef.current;

      if (e.which === 13) {
        // on enter
        const val = searchBar && searchBar.value;
        if (!val && !isCommaList) return;

        if (highlightedSuggestion && highlightedSuggestion.url) {
          if (highlightedSuggestion.targetHref) {
            onSearchComplete();
            return window && window.open(highlightedSuggestion.url);
          } else {
            onSearchComplete();
            return history.push({
              pathname: highlightedSuggestion.url,
              search: championSearchParams.searchParams || "",
            });
          }
        }

        search(val);
        searchBar.blur();
      }
    },
    [search, highlightedSuggestion],
  );

  useEffect(() => {
    setInit(true);

    if (defaultValue) {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (init) {
      if (inputRef && inputRef.current) {
        inputRef.current.addEventListener("keypress", handleKeyPress);

        return () => {
          if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener("keypress", handleKeyPress);
          }
        };
      }
    }
  }, [init, handleKeyPress]);

  useEffect(() => {
    if (isInteracting) {
      let suggestions = getSuggestions(value, clientRegion, playerInfoSuggestions);
      if (value.trim().length === 0) {
        suggestions = getRecentlySearched();
      }
      setSuggestions(suggestions);
    }
  }, [isInteracting]);

  useEffect(() => {
    if (!playerInfoSuggestionsLoading) {
      const newSuggestionObj = playerInfoSuggestionsData?.playerProfileSuggestions
        ? [...playerInfoSuggestionsData.playerProfileSuggestions].sort((a, b) => b.rankScore - a.rankScore)
        : [];
      setPlayerInfoSuggestions(newSuggestionObj);
    }
  }, [playerInfoSuggestionsLoading, JSON.stringify(playerInfoSuggestionsData)]);

  useEffect(() => {
    if (playerInfoSuggestionsChanged) {
      setSuggestions(getSuggestions(value, clientRegion, playerInfoSuggestions));
    }
  }, [value, clientRegion, JSON.stringify(playerInfoSuggestions), playerInfoSuggestionsChanged]);

  useEffect(() => {
    if (lastPage && location.pathname !== lastPage && value === lastSearchValue) {
      setValue("");
      setLastPage(undefined);
    }
  }, [location.pathname, lastSearchValue, value]);

  useEffect(() => {
    if (multiSearching) {
      if (isCommaList || isParsedText) {
        setMultisearching(false);
        const commaList = value
          .split(",")
          .map((entry) => entry.trim())
          .filter((entry) => entry !== "");
        const { isMultiSearch, names } = stripMultiSearchBar(value);
        const multiSearchString = `summoners=${(isCommaList ? commaList : names).join(",")}&region=${clientRegion}`;
        const navPath = AppRoutes.MULTISEARCH;
        if (window) {
          onSearchComplete();
          return history.push({
            pathname: navPath,
            search: multiSearchString,
          });
        }
      }
    }
  }, [multiSearching]);

  const onSearchClick = (e) => {
    search(value);
  };

  const onChange = (event, { newValue, method }) => {
    if (method === "type") {
      !isInteracting && setInteraction(true);
      initInteracting.current = true;
    }

    setValue(newValue);
    if (lastSearchValue) {
      setLastSearchValue(undefined);
    }
  };

  const onMouseDown = (e) => {
    !isInteracting && setInteraction(true);
    initInteracting.current = true;
  };

  const onSuggestionHighlighted = ({ suggestion }) => {
    setHighlightedSuggestion(suggestion);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, method }) => {
    if (suggestionValue && (method === "enter" || method === "click")) {
      // Check if users clicked a tag, then use that href
      if (event.target && event.target.href) {
        onSearchComplete();
      } else if (suggestion.targetHref) {
        return window && window.open(suggestion.url);
      } else if (suggestion.url) {
        // If only 1 available tag, use that tag's url
        if (suggestion.tags && suggestion.tags.length === 1) {
          onSearchComplete();
          history.push({
            pathname: suggestion.tags[0].url,
            search: championSearchParams || "",
          });
        } else {
          onSearchComplete();
          history.push({
            pathname: suggestion.url,
            search: championSearchParams || "",
          });
        }
      } else {
        search(suggestionValue);
      }
      inputRef && inputRef.current.blur();
    }
  };

  const onBlur = (event, { highlightedSuggestion }) => {
    initInteracting.current && !isInteracting && setInteraction(true);
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    let suggestions = getSuggestions(value, clientRegion, playerInfoSuggestions);
    if (value.trim().length === 0) {
      suggestions = getRecentlySearched();
    } else {
      debouncedGetPlayerInfoSuggestions({
        variables: {
          query: value.toLowerCase(),
          regionId: clientRegion,
        },
      });
    }
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    ref: inputRef,
    placeholder: !isCommaList ? placeholder || "Search Your Summoner" : "Name1, Name2, ...",
    name: "query",
    value,
    onChange,
    spellCheck: false,
    id: "super-search-bar",
    onBlur,
    onMouseDown,
    autoFocus: autoFocus ? autoFocus : false,
  };

  let finalSuggestions = noDropdown || isCommaList || multisearchOnly ? [] : suggestions;

  const autosuggest = (
    <Autosuggest
      multiSection={true}
      suggestions={finalSuggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      onSuggestionHighlighted={onSuggestionHighlighted}
      onSuggestionSelected={onSuggestionSelected}
      shouldRenderSuggestions={() => isInteracting}
      focusInputOnSuggestionClick={false}
      inputProps={inputProps}
    />
  );

  if (isMasthead) {
    return (
      <div className={`masthead-ugg-search-bar ${theme || "dark"}-theme`}>
        {autosuggest}
        <SearchBarRegionSelector />
        <div className="autosuggest-button" onClick={onSearchClick}>
          <SearchIconWhite />
        </div>
        {searching && (
          <div className="spinthatshit-loader">
            <div className="spinner" />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={`autosuggest-container`}>
        {autosuggest}
        <SearchBarRegionSelector noLabels />
        <div className="autosuggest-button" onClick={onSearchClick}>
          <div className="region-search">
            <SearchIconGrey />
          </div>
        </div>
      </div>
    );
  }
};

export default SearchBar;
