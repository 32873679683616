import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { ALL_ROLE_OPTIONS, QUEUE_TYPE_OPTIONS, REGION_OPTIONS, RANK_OPTIONS_WITH_D2, ALL_CHAMPIONS_OPTIONS } from "../common";

// ------------------------------------
// COMBAT
// ------------------------------------

export const COMBAT_QUEUE_TYPE_OPTIONS = [
  { value: QueueTypeS.RANKED_SOLO, label: "Ranked Solo" },
  { value: QueueTypeS.RANKED_FLEX, label: "Ranked Flex" },
  { value: QueueTypeS.QUICKPLAY, label: "Quickplay" },
  { value: QueueTypeS.SWIFTPLAY, label: "Swiftplay" },
  { value: QueueTypeS.NORMAL_DRAFT, label: "Normal Draft" },
  { value: QueueTypeS.ARAM, label: "ARAM" },
];

export const COMBAT_OPTIONS = {
  queueType: COMBAT_QUEUE_TYPE_OPTIONS,
  role: ALL_ROLE_OPTIONS,
  region: REGION_OPTIONS,
  rank: RANK_OPTIONS_WITH_D2,
  allChampions: ALL_CHAMPIONS_OPTIONS,
  patch: { options: "latest", max: 5 },
};

export const COMBAT_DEFAULT = {
  queueType: COMBAT_OPTIONS.queueType[0].value,
  role: COMBAT_OPTIONS.role[0].value,
  region: COMBAT_OPTIONS.region[0].value,
  rank: COMBAT_OPTIONS.rank[1].value,
  patch: COMBAT_OPTIONS.patch.options,
  allChampions: "false",
};
